import {getInitials} from '@/utils/user';

const name = 'user';
export default {
  name,
  resolvers: {
    initials: ({full_name}) => {
      return getInitials(full_name);
    },
  },
};
