/* eslint-disable max-len*/
const translations = {
  '$vuetify': {
    'close': 'cerrar',
    'noDataText': 'No hay data disponible',
    'timePicker': {
      'am': 'AM',
      'pm': 'PM',
    },
    'datePicker': {
      'prevMonthAriaLabel': 'Anterior',
      'nextMonthAriaLabel': 'Siguiente',
      'itemsSelected': 'Seleccionados',
      'prevYearAriaLabel': 'Anterior',
      'nextYearAriaLabel': 'Siguiente',
    },
    'badge': 'badge',
    'dataIterator': {
      'noResultsText': 'No hay resultados disponibles',
      'loadingText': 'Cargando...',
    },
    'dataTable': {
      'itemsPerPageText': 'Filas por página:',
      'ariaLabel': {
        'sortDescending': 'Orden descendente.',
        'sortAscending': 'Orden ascendente.',
        'sortNone': 'Sin ordenar.',
        'activateNone': 'Pulse para quitar orden.',
        'activateDescending': 'Pulse para ordenar descendente.',
        'activateAscending': 'Pulse para ordenar ascendente.',
      },
      'sortBy': 'Ordenado por',
    },
    'dataFooter': {
      'itemsPerPageText': 'Elementos por página:',
      'itemsPerPageAll': 'Todos',
      'nextPage': 'Página siguiente',
      'prevPage': 'Página anterior',
      'firstPage': 'Primer página',
      'lastPage': 'Última página',
      'pageText': '{0}-{1} de {2}',
    },
    'pagination': {
      'ariaLabel': {
        'previous': 'Anterior',
        'next': 'Siguiente',
        'wrapper': 'Envoltorio',
        'currentPage': 'Pagina Actual',
        'page': 'Pagina',
      },
    },
  },
  'error': {
    'data_exception': 'vaya, hubo un problema, inténtalo de nuevo más tarde',
    'default': 'vaya, hubo un problema, inténtalo de nuevo más tarde',
    'wrong_password': 'la contraseña no es válida o el usuario no tiene una contraseña',
    'user_not_found': 'no hay registro de usuario correspondiente a este correo electrónico',
    'weak_password': 'la contraseña debe tener al menos 6 caracteres',
    'email_already_in_use': 'la dirección de correo electrónico ya está en uso por otra cuenta',
    'invalid_action_code': 'el código de acción no es válido, esto puede suceder si el código está mal formado, caducado o ya se ha utilizado',
    'too_many_requests': 'demasiadas solicitudes, espere un momento y vuelva a intentarlo',
    'integration_email_already_exists': 'ya tienes una integración registrada con este correo electronico',
    'integration_token_already_exists': 'ya tienes una integración registrada con el token recibido',
    'existing_workspaces_use_user_integration': 'esta integración esta siendo usada por un espacio de trabajo',
    'workspace_integration_already_exists': 'solo se permite una integración por aplicación',
    'loading_workspace_integrations': 'hubo un problema cargando las integraciones, por favor contacta el dueño del workspace y verifica tus integraciones personales',
    'expired_action_code': 'el código de acción ha expirado',
    'invalid_email': 'la dirección de correo electrónico está mal formateada',
    'missing_personal_integration': 'necesitas configurar una integración personal para {0}, por favor ve a la configuración de usuario',
    'only_one_app_per_workspace': 'solo una integracion por espacio de trabajo es permitida',
    'only_one_app_per_project': 'solo un proyecto por integración es permitido',
    'overlapping_task_intervals_not_allowed': 'ya existe un intervalo de tiempo asociado a esta tarea en ese rango',
  },
  'success': {
    'verification_email_sent': 'correo electrónico de verificación enviado correctamente, revise su bandeja de entrada',
    'reset_password_email_sent': 'correo electrónico de reestablecer contraseña enviado con éxito, verifique su bandeja de entrada',
    'change_password_successful': 'la contraseña se modificó correctamente',
    'update_profile': 'tu información fue actualizada exitosamente',
    'save_settings': 'configuración guardada exitosamente',
    'workspace_left': 'espacio de trabajo abandonado exitosamente',
    'workspace_integrated': 'espacio de trabajo integrado exitosamente',
    'workspace_status_changed': 'estado de la integración del espacio de trabajo actualizada exitosamente',
    'workspace_invitation_sent': 'invitación al workspace enviada exitosamente',
    'member_deleted': 'miembro eliminado exitosamente',
    'role_updated': 'rol actualizado exitosamente',
    'invitation_cancelled': 'invitación cancelada exitosamente',
    'project_integrated': 'proyecto integrado exitosamente',
    'member_joined_project': 'el miembro se ha unido al proyecto exitosamente',
    'member_removed_from_project': 'el miembro se ha removido del proyecto exitosamente',
    'member_invited_to_workspace': 'has sido invitado a "{workspaceName}", inicia sesión para continuar',
  },

  // General
  'add': 'añadir',
  'cancel': 'cancelar',
  'save': 'guardar',
  'delete': 'eliminar',
  'removing': 'Eliminando',
  'yes': 'si',
  'no': 'no',
  'me': 'yo',
  'search': 'buscar',
  'refresh': 'actualizar',
  'pagination': 'página {0} de {1}',
  'items_per_page': 'items por página',

  // labels
  'full_name': 'nombre completo',
  'name': 'nombre',
  'email': 'correo electrónico',
  'username': 'nombre de usuario',
  'password': 'contraseña',
  'new_password': 'nueva contraseña',
  'confirm_password': 'confirmar contraseña',
  'stay_logged_in': 'permanecer conectado',
  'accept_policy': 'acepto los términos y condiciones de uso',
  'color': 'color',
  'logo': 'logo',
  'workspace_name': 'nombre del espacio de trabajo',
  'time_zone': 'zona horaria',
  'time_format': 'formato de hora',
  'work_start': 'hora laboral inicio',
  'work_end': 'hora laboral fin',
  'minimum_hours': 'horas laborales minimas',
  'tracker_start': 'hora inicio de seguimiento',
  'tracker_end': 'hora final de seguimiento',
  'industry': 'industria',
  'country': 'país',
  'currency': 'moneda',
  'rate': 'tasa horaria',
  'enable': 'habilitar',
  'disable': 'deshabilitar',
  'approve_only': 'aprobar unicamente',
  'offline_time': 'tiempo fuera de línea',
  'screenshot_interval': 'intervalo de captura de pantalla',
  'screenshot_quality': 'calidad de captura de pantalla',
  'allow_screenshot': 'permitir tomar capturas de pantalla',
  'blur_screenshot': 'difuminar capturas de pantalla',
  'see_dashboards_reports': 'ver tableros/reportes',
  'disable_url_tracking': 'deshabilitar seguimiento de url',
  'disable_windows_title': 'deshabilitar titulo de ventana',
  'disable_private_time': 'deshabilitar tiempo privado',
  'disable_calendar': 'deshabilitar calendario',
  'allow_project_cost': 'permitir costo de proyecto',
  'hide_owner': 'ocultar propietario',
  'hide_schedule': 'ocultar calendario',
  'hide_colleagues': 'ocultar colegas',
  'admins': 'administradores',
  'everyone': 'todos',
  'tracking_days': 'dias de seguimiento',
  'working_days': 'dias habiles',
  'mo': 'lu',
  'tu': 'ma',
  'we': 'mi',
  'th': 'ju',
  'fr': 'vi',
  'sa': 'sa',
  'su': 'do',
  'public': 'publico',
  'private': 'privado',
  'shared': 'compartido',
  'start_time': 'inicio',
  'stop_time': 'fin',
  'date': 'fecha',

  // Error labels
  'passwords_dont_match': 'las contraseñas no coinciden',

  // links
  'forgot_password': '¿olvidó su contraseña?',
  'dont_have_account': '¿no tienes una cuenta?',
  'already_have_account': 'ya tengo una cuenta',

  // Button
  'send_email': 'enviar correo electrónico',
  'sign_in': 'iniciar sesión',
  'sign_up': 'registrarse',
  'continue_with': 'continuar con',
  'set_new_password': 'cambiar contraseña',
  'edit': 'editar',
  'create': 'crear',
  'add_new_btn': '@:create',
  'invite': 'invitar',
  'resend_invite': 'reenviar invitación',
  'cancel_invite': 'cancelar invitación',
  'role': 'rol',
  'status': 'estado',
  'actions': 'acciones',

  // Rules
  'is_required': 'requerido',
  'agree_to_continue': 'debe aceptar para continuar',
  'password_dont_match': 'las contraseñas no coinciden',

  // Titles
  'verify_email': 'verifique su bandeja de entrada',
  'verify_email_description': 'una vez que verifique su dirección de correo electrónico, ¡usted y su equipo pueden comenzar a usar + tracker!',
  'didnt_receive_email': 'no recibiste el correo?',
  'resend_email': 'reenviar',
  'title': 'titulo',

  // Drawer
  'time_entries': 'entradas de tiempo',
  'tasks': 'tareas',
  'dashboards': 'tableros',
  'reports': 'reportes',
  'settings': 'configuración',
  'logout': 'cerrar sesión',


  // Settings
  'basic_settings': 'configuración básica',
  'display': 'visualización',
  'profile_photo': 'foto de perfil',
  'existing_integrations': 'integraciones existentes',
  'upload_photo': 'sube tu foto',
  'upload_logo': 'sube tu logo',
  'remove_photo': 'quitar foto',
  'workspace_settings': 'configuración de espacio de trabajo',
  'workspace_logo': 'logo',
  'time_tracking': 'seguimiento de tiempo',
  'track_time_in_personal_workspace': 'seguir tiempo en espacio de trabajo personal',

  // Subject messages
  'create_subject': 'crear {0}',
  'edit_subject': 'editar {0}',
  'add_subject': 'agregar {0}',
  'subject_added': '{subject} agregad{gender, select, male{o} female{a} other{o}} exitosamente',
  'subject_updated': '{subject} actualizad{gender, select, male{o} female{a} other{o}} exitosamente',
  'subject_removed': '{subject} eliminad{gender, select, male{o} female{a} other{o}} exitosamente',
  'subject_remove_confirm': '¿deseas eliminar este {subject}?',
  'select_subject': 'seleccionar {0}',
  'field_updated': '{field} actualizado exitosamente',

  // subjects
  'integration': 'integración | integraciones',
  'workspace': 'espacio de trabajo | espacios de trabajo',
  'task': 'tarea | tareas',
  'client': 'cliente | clientes',
  'member': 'miembro | miembros',
  'project': 'proyecto | proyectos',
  'action': 'acción | acciones',
  'workspace_integration': 'integración de espacio de trabajo',
  'project_integration': 'integración de proyecto',
  'time_interval': 'intervalo de tiempo | intervalos de tiempo',
  'interval': 'intervalo | intervalos',

  // Verify email success
  'email_successfully_verified': 'tu correo electronico ha sido verficado exitosamente',
  'start_using_tracker': 'ahora puedes comenzar a usar +tracker!',
  'go_to_dashboard': 'ir a tablero',
  'language': 'lenguaje',

  // languages
  'spanish': 'español',
  'english': 'inglés',

  // Workspace settings tabs
  'time_settings': '@:settings de tiempo',
  'billing': 'facturación',
  'features': 'características',
  'integrations': 'integraciones',
  'members': 'miembros',
  'permissions': 'permisos',
  'owner': 'dueño',
  'leave_workspace': 'dejar espacio de trabajo',

  // Confirm messages
  'confirm_leave_workspace': 'estas seguro que quieres dejar este espacio de trabajo?',
  'confirm_delete_member': 'esta seguro que desea eliminar este miembro?',
  'confirm_cancel_invite': 'esta seguro que desea cancelar la invitación?',
  'confirm_delete_member_project': 'esta seguro que desea remover estos miembros del projecto?',

  // Integrations
  'no_user_integration_available': 'no tienes una integración personal asociada, por favor ve a la configuración de usuario para agregar una',
  'no_workspace_integration_available': 'este espacio de trabajo no tiene ninguna integración asociada, por favor agrega una en la pestaña de integraciónes del espacio del trabajo (Solo los dueños pueden hacer esto)',
  'only_owners_can_add_integrations': 'solo el dueño del workspace puede agregar integraciones',
  'workspace_doesnt_have_integrations': 'este espacio de trabajo no tiene ninguna integración asociada, agrega una en la pestaña de integraciones',

  'my_account': 'mi cuenta',
  'dark_mode': 'modo oscuro',

  // Member status
  'uninvited': 'no invitado',
  'invited': 'invitado',
  'active': 'activo',

  // Member roles
  'admin': 'admin',

  // Projects
  'allow_project_access': 'permitir acceso al proyecto',
  'unallow_project_access': 'quitar acceso al proyecto',
  'allowed': 'permitido',
  'unallowed': 'no permitido',
  'selected': 'seleccionados',
  'can_member_estimate': 'Miembros pueden estimar tareas',
  'can_member_complete': 'Miembros pueden completar tareas',

  // Tasks
  'due_date': 'fecha de vencimiento',
  'time_estimate': 'tiempo estimado',
  'time_spent': 'tiempo gastado',
  'remaining': 'restante',
  'manage_integrations': 'manejar integraciones',
  'invalid_human_time': 'formato de duración invalido',
  'time_format_placeholder': 'ejemplo: 3h 30m',
  'assignees': 'asignados',
  'none': 'Nadie',
  'show_completed': 'mostrar completadas',

  // Time Entries
  'no_data_today': 'No hay datos disponibles para este día.',
  'productivity': 'rendimiento',
  'activity_pulse': 'Pulso de actividad',
  'highlight_summary': 'Resumen Destacado',
  'inactive': 'inactivo',
  'pending': 'pendiente',
  'recommended': 'Recomendada',
  'sync_intervals': 'Sinc. Intervalos',
  'monitor_tracked': 'Tiempo en Monitor',
  'button_interval': 'Agregar Intervalo',
  'activity': 'actividad',
  'task_time': 'Tiempo de tarea',
  'time': 'tiempo',
  'completed': 'completado',
  'based_on_your_behavior': 'Basado en su comportamiento',
  'timeline': 'línea de tiempo',
  'new_task': 'new task',
  'extra': 'adicional',
  'tracked': 'medido',
  'go_to': 'ir a',
  'sync_time_intervals': 'sincronizar intervalos de tiempo',
  'time_entry_detail_title': 'detalle de entrada de tiempo',
  'delete_screenshot': 'Eliminar Screenshot',
  'screenshot': 'intervalo de tiempo',
  'activity_average': 'promedio de actividad',
  'related_task': 'tareas relacionadas',
  // workspace invitation
  'workspace_invitation_new_user': 'estás invitado al espacio de trabajo {workspaceName}, por favor ingresa una contraseña para continuar!',
  'no_result_found': 'ningún resultado encontrado',

  // filtros
  'filters': 'filtros',
  'others': 'otros',
  'no_data_available': 'no hay data disponible',
  'apply_filter': 'aplicar filtro',
  'select_all': 'seleccionar todos',
  // date menu
  'today': 'hoy',
  'week': 'esta semana',
  'month': 'este mes',
  'year': 'este año',
  'date_range': 'rango de fechas',

  // dashboard
  'time_tracked': 'tiempo trackeado',
  'jan': 'Ene',
  'feb': 'Feb',
  'mar': 'Mar',
  'apr': 'Abr',
  'may': 'May',
  'jun': 'Jun',
  'jul': 'Jul',
  'aug': 'Ago',
  'sep': 'Sep',
  'oct': 'Oct',
  'nov': 'Nov',
  'dec': 'Dic',
  'mon': 'Lun',
  'tue': 'Mar',
  'wed': 'Mie',
  'thu': 'Jue',
  'fri': 'Vie',
  'sat': 'Sab',
  'sun': 'Dom',
  'no_project': 'Sin proyecto',
  'no_task': 'Sin tarea',
  'no_client': 'Sin cliente',
  'mode_calendar': 'calendario',
  'group_by': 'agrupar por',
  'summary': 'resumen',
  'no_dashboard': '!No tienes actividad para mostrar en esta consulta!',
  'total_hours_tracked': 'total horas trackeadas',
  'top_project': 'proyecto mas trackeado',
  'top_task_tracked': 'tarea mas trackeada',
  'comparison_today': 'comparado con el último dia',
  'comparison_week': 'comparado con la última semana',
  'comparison_month': 'comparado con el último mes',
  'comparison_year': 'comparado con el último año',
  'comparison_range': 'comparado con el último rango',
  'total_hours': 'horas totales',
  'upload': 'subir',
  'me_mode': 'modo personal',
};

export const grammaticalGender = {
  integration: 'female',
};
export default translations;
