import {ClickUpApi} from '@/services/integrations/clickup/ClickUpApi';
import {AsanaApi} from '@/services/integrations/asana/AsanaApi';


const integrationApis = {
  'ClickUp': ClickUpApi,
  'Asana': AsanaApi,
};

export default integrationApis;
