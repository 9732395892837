export default class AsanaMapper {
  static mapWorkspaceFromApi(asanaWorkspace) {
    return {
      id: asanaWorkspace.gid,
      name: asanaWorkspace.name,
    };
  }

  static mapProjectFromApi(asanaProject) {
    return {
      id: asanaProject.gid,
      name: asanaProject.name,
    };
  }

  static mapMemberFromApi(asanaMember) {
    return {
      user: {
        id: asanaMember.gid,
        name: asanaMember.name,
        email: asanaMember.email,
      },
    };
  }
}
