/* eslint-disable  no-invalid-this */
/**
 * Function to close a modal, it detects if the route was access by directly
 * (Opened by a link click or typing the url) in this case,
 * the directAccess flag is set to false and
 * the user is redirected to the default route otherwise,
 * the user was already in the app and he will go back to the last page
 * @return {Promise<void>}
 */
export async function closeModal() {
  if (this.$route.meta.directAccess) {
    this.$route.meta.directAccess = false;
    await this.$router.push({name: 'dashboard'});
  } else {
    await this.$router.back();
  }
}

/**
 * Replace parameters with the : prefix in the given url
 * example: /url/:id will be replaced into /url/1
 * @param {string} path - Full url path to replace the parameters
 * @param {object} params - Parameters to replace by key in the url
 * @return {string} path - Path with replaced parameters
 */
export function replaceUrlParameters(path, params) {
  let fullPath = path.replace('(\\d+)', '');
  for (const [key, value] of Object.entries(params)) {
    fullPath = fullPath.replace(new RegExp(`:${key}`, 'g'), value);
  }
  return fullPath;
}

/**
 * Factory for changing tab functions that changes the current location state
 * without having to push a new URL into the history.
 * It receives a path like `/path/:param/:activeTab` and it receives the
 * new tab index, look for the new tab name and finally
 * replaces the received route structure with the current route params
 * but with the new activeTab parameter.
 * @param {String} path
 * @return {Function}
 */
export function buildChangeTab(path) {
  return async function changeTab(tab) {
    const {params} = this.$route;
    const tabName = this.tabs[tab].name;
    params.activeTab = tabName;
    this.activeTab = tabName;
    const url = replaceUrlParameters(path, params);
    window.history.replaceState({}, null, url);
  };
}

export const USER_SETTING_TABS = [
  {name: 'basic_settings'},
  {name: 'integrations', params: [2]},
  {name: 'display'},
  {name: 'features'},
];
export const WORKSPACE_SETTINGS_TABS = [
  {name: 'basic_settings'},
  {name: 'integrations'},
  {name: 'members'},
  {name: 'time_settings'},
  {name: 'billing'},
  {name: 'features'},
  {name: 'permissions'},
];

/**
 * Computed property to get the max height allowed
 * for the modals depending on the screen resolution
 * @return {String} Max height for modal
 */
export function maxHeightModal() {
  if (this.dynamicHeight) {
    return 'unset';
  }
  const isBreakpointMdAndDown = this.$vuetify.breakpoint.mdAndDown;

  return isBreakpointMdAndDown ? '100vh' : '85vh';
}

/**
 * Computed property to get the max width allowed
 * for the modals depending on the screen resolution
 * @return {String} Max width for modal
 */
export function maxWidthModal() {
  return this.$vuetify.breakpoint.mdAndDown ? '100vw' : '80vw';
}


export const MODAL_HEIGHTS = {
  TITLE: 81,
  ACTIONS: 80,
  TABS: 50,
};

export const DRAWER_WIDTH = {
  EXPANDED: 256,
  MINI: 48,
};
