<template>
  <div>
    <v-snackbar
      v-for="(snackbar, index) in snackbars"
      :key="snackbar.id"
      :timeout="DEFAULT_TIMEOUT"
      :color="snackbar.isSuccess? 'success':'error'"
      :value="true"
      bottom
      :style="`bottom: ${(index * 65) + 8}px`"
      @input="REMOVE(snackbar)"
    >
      <div class="d-flex justify-space-between align-center">
        <span class="subtitle-1">
          {{ $t(snackbar.text, snackbar.params) | capitalize }}
        </span>
        <v-btn
          text
          dark
          icon
          @click="REMOVE(snackbar)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';

export default {
  name: 'BaseSnackbar',
  data() {
    return {
      DEFAULT_TIMEOUT: 5000,
    };
  },
  computed: {
    ...mapState('snackbar', ['snackbars']),
  },
  methods: {
    ...mapMutations('snackbar', ['REMOVE', 'PUSH']),
  },
};
</script>

