import {mapSubjectSettings} from '@/utils/settings';
import {lightenColor} from '@/utils/colors';
const name = 'project';
export default {
  name,
  resolvers: {
    formatted_settings: mapSubjectSettings,
    lighter_color({color}) {
      return lightenColor(color, 15);
    },
    hover_color({color}) {
      return lightenColor(color, -10);
    },
  },
};
