export const USER_FILTER_KEY = 'userId';
export const PROJECT_FILTER_KEY = 'projectId';
export const TASK_FILTER_KEY = 'taskId';

const getDefaultState = () => ({
  filters: {
    [USER_FILTER_KEY]: null,
    [PROJECT_FILTER_KEY]: null,
    [TASK_FILTER_KEY]: null,
  },
});
const mutations = {
  SET_USER_FILTER(state, userId) {
    state.filters.userId = userId;
  },
  SET_PROJECT_FILTER(state, projectId) {
    state.filters.projectId = projectId;
  },
  SET_TASK_FILTER(state, taskId) {
    state.filters.taskId = taskId;
  },
  SET_FILTER(state, {filter, value}) {
    state.filters[filter] = value;
  },
  CLEAR_FILTERS(state) {
    state.filters.userId = null;
    state.filters.projectId = null;
    state.filters.taskId = null;
  },
  CLEAR_FILTER(state, filter) {
    state.filters[filter] = null;
  },
};

const getters = {
  GET_USER_FILTER(state) {
    return state.filters.userId;
  },
  GET_PROJECT_FILTER(state) {
    return state.filters.projectId;
  },
  GET_TASK_FILTER(state) {
    return state.filters.taskId;
  },
};

const actions = {};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  getters,
  actions,
  getDefaultState,
};
