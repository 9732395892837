/**
 * Factory to generate a middleware to check
 * if the active tab is in the provided tabs
 * @param {Array} tabs - Array of tabs with name
 * @return {Function} Middleware
 */
export function validateTabs(tabs) {
  return async function beforeRouteEnter({to, next}) {
    const {activeTab} = to.params;
    const tab = tabs.find((tab)=>tab.name === activeTab);
    if (!tab) {
      return next('/not-found');
    }
    return next();
  };
}
