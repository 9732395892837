export const ACTIONS = {
  VIEW: 'view',
  UPDATE: 'update',
  DELETE: 'delete',
  CREATE: 'create',
  ADD: 'add',
  LEAVE: 'leave',
  INVITE: 'invite',
  CANCEL_INVITE: 'cancel_invite',
  JOIN: 'join',
  MODIFY: 'modify',
  FILTER_MEMBER: 'filter_member',
};
export const ACTIONS_ALIASES = {
  [ACTIONS.MODIFY]: [ACTIONS.JOIN, ACTIONS.DELETE],
};

export const FIELDS = {
  MEMBER: {
    ROLE: 'role',
  },
  TASK: {
    TIME_ESTIMATE: 'time_estimate',
    COMPLETED: 'completed',
  },
};

const UPPERCASE_HANDLER = {
  get(target, prop) {
    if (typeof prop === 'string') return target[prop.toUpperCase()];
    return target[prop];
  },
};

const BASE_RESOURCES = {
  MEMBER: 'member',
  PROJECT: 'project',
  WORKSPACE_INTEGRATION: 'workspace_integration_app',
  PROJECT_INTEGRATION: 'project_integration_app',
  PROJECT_MEMBER: 'project_member',
  WORKSPACE: 'workspace',
  TASK: 'task',
  DASHBOARD: 'dashboard',
  PAGES: {
    PROJECT: {
      CREATE: 'project.create',
      SETTINGS: {
        BASIC_SETTINGS: 'basic_settings',
        INTEGRATIONS: 'integrations',
        MEMBERS: 'members',
        BILLING: 'billing',
        PERMISSIONS: 'permissions',
      },
    },
    WORKSPACE: {
      SETTINGS: {
        BASIC_SETTINGS: 'basic_settings',
        INTEGRATIONS: 'integrations',
        MEMBERS: 'members',
        TIME_SETTINGS: 'time_settings',
        BILLING: 'billing',
        FEATURES: 'features',
        PERMISSIONS: 'permissions',
      },
    },
  },
};

const PROXY_RESOURCES = {...BASE_RESOURCES};
PROXY_RESOURCES.PAGES.WORKSPACE.SETTINGS = new Proxy(
    BASE_RESOURCES.PAGES.WORKSPACE.SETTINGS,
    UPPERCASE_HANDLER
);
PROXY_RESOURCES.PAGES.PROJECT.SETTINGS = new Proxy(
    BASE_RESOURCES.PAGES.PROJECT.SETTINGS,
    UPPERCASE_HANDLER
);
export const RESOURCES = PROXY_RESOURCES;

