import Vue from 'vue';
import Vuetify, {
  VListItemAvatar,
  VListItemContent,
  VListItem,
  VImg,
  VIcon,
  VBadge,
  VCard,
  VCardSubtitle,
  VTooltip,
  VBtn,
} from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import i18n from './i18n';
import BaseTextField from '@/components/core/input/BaseTextField';
import BaseImageInput from '@/components/core/input/BaseImageInput';
import {getTheme} from '@/utils/theme';
import Settings from '@/components/icons/Settings';
import CheckboxMarkedOutline from '@/components/icons/CheckboxMarkedOutline';
import CheckboxMarked from '@/components/icons/CheckboxMarked';
import {Ripple} from 'vuetify/lib/directives';
import IntegrationLogo from '@/components/integration/IntegrationLogo';
import Facebook from '@/components/icons/Facebook';
import Google from '@/components/icons/Google';
import Github from '@/components/icons/Github';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});
Vue.component('BaseImageInput', BaseImageInput);
Vue.component('BaseTextField', BaseTextField);
/** These components are registered globally because they are used inside
 * functional components and this is a limitation of vuetify/a-la-carte
**/
Vue.component('VTooltip', VTooltip);
Vue.component('VBtn', VBtn);
Vue.component('VImg', VImg);
Vue.component('VIcon', VIcon);
Vue.component('VListItem', VListItem);
Vue.component('VListItemAvatar', VListItemAvatar);
Vue.component('VListItemContent', VListItemContent);
Vue.component('IntegrationLogo', IntegrationLogo);
Vue.component('VBadge', VBadge);
Vue.component('VCard', VCard);
Vue.component('VCardSubtitle', VCardSubtitle);


const navy_blue = {
  lighten2: '#364554',
  lighten3: '#4B5C6D',
};

const remove = {
  base: '#FF3834',
};

export const primary = {
  base: '#1354FF',
  lighten1: '#336DFF',
  lighten2: '#477BFF',
  lighten3: '#5C8AFF',
  lighten4: '#7099FF',
  lighten5: '#85A7FF',
};

export const gray = {
  darken3: '#212121',
  darken2: '#303030',
  darken1: '#424242',
  base: '#535353',
  lighten1: '#717171',
  lighten2: '#888888',
  lighten3: '#9F9F9F',
  lighten4: '#D7D7D7',
  lighten5: '#EDEDED',
  lighten6: '#E4E4E4',
  lighten7: '#F5F5F5',
  lighten8: '#E5E5E5',
};

const anchor = '#038fff';

export default new Vuetify({
  theme: {
    dark: getTheme(),
    themes: {
      dark: {
        remove,
        navy_blue,
        accent_blue: '#1354FF',
        subtitle: '#ffffff',
        primary,
        scaffold: {
          base: colors.grey.darken4,
          darken1: '#222',
        },
        gray,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        anchor,
      },
      light: {
        remove,
        subtitle: '#000000',
        navy_blue,
        accent_blue: '#1354FF',
        primary,
        accent: {
          base: '#28323D',
        },
        scaffold: {
          base: '#28323D',
          darken1: '#171F27',
        },
        gray,
        anchor,
      },
    },
    options: {
      customProperties: true,
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    iconfont: 'mdi',
    values: {
      facebook: {
        component: Facebook,
      },
      github: {
        component: Github,
      },
      google: {
        component: Google,
      },
      settings: {
        component: Settings,
      },
      checkboxCompletedOn: {
        component: CheckboxMarked,
      },
      checkboxCompletedOff: {
        component: CheckboxMarkedOutline,
      },
    },
  },
});
