import * as firebase from 'firebase/app';
import 'firebase/auth';
import router from '@/router';
import {formatError} from '@/utils/store';

const getDefaultState = ()=> ({
  code: null,
  signInLink: null,
});

const mutations = {
  SET_CODE(state, code) {
    state.code = code;
  },
  SET_SIGN_IN_LINK(state, link) {
    state.signInLink = link;
  },
};

const actions = {
  async handleEmailAction({commit, dispatch}, data) {
    const {mode, oobCode} = data;
    if (mode === 'verifyEmail') {
      await dispatch(mode, data);
    } else if (mode === 'resetPassword') {
      commit('SET_CODE', oobCode);
      await router.push('/reset-password-confirm');
    } else {
      await router.push('/');
    }
  },
  async verifyEmail({commit, dispatch}, {oobCode}) {
    const form = 'SignIn';
    try {
      await firebase.auth().applyActionCode(oobCode);
      commit('auth/UPDATE_USER', {is_verified: true}, {root: true});
      await dispatch('auth/refreshToken');
      await dispatch('auth/getOrCreateHasuraUser', null, {root: true});
      await router.push('verify-email-success');
    } catch (e) {
      commit('SET_CODE', null);
      commit('form/SET_ERROR', {
        form,
        message: formatError(e.code || e.message),
      }, {root: true});
      await dispatch('logOut');
      await router.push('sign-in');
    }
  },
  async resetPassword({state, commit}, {password}) {
    await firebase.auth().confirmPasswordReset(state.code, password);
    commit('SET_CODE', null);
  },
  async signInWithEmailLink({state, commit}, {email}) {
    if (!state.signInLink) {
      throw new Error('Unable to sign in with email link, ' +
        'there is no sign in link available');
    }
    await firebase.auth().signInWithEmailLink(email, state.signInLink);
    commit('SET_SIGN_IN_LINK', null);
  },
  async changePassword(changePassword, {password}) {
    const user = firebase.auth().currentUser;
    await user.updatePassword(password);
  },
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
  getDefaultState,
};
