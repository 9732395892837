const files = require.context('.', false, /\.js$/);
const resolvers = {};
const mutations = {};
const queries = {};

files.keys().forEach((fileName) => {
  if (fileName === './index.js') return;
  const {
    name = fileName.replace(/(\.\/|\.js)/g, ''),
    mutations: currentMutations,
    resolvers: currentResolvers,
    queries: currentQueries,
  } = files(fileName).default;
  Object.assign(mutations, currentMutations);
  Object.assign(queries, currentQueries);
  Object.assign(resolvers, {[name]: currentResolvers});
});
resolvers['Mutation'] = mutations;
resolvers['Query'] = queries;
export default resolvers;
