import apolloClient from '@/apollo/client';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import {
  insertUserWorkspace,
  updateUserWorkspace,
} from '@/graphql/workspace.gql';
import {MEMBER_ROLE} from '@/utils/enums';
import Vue from 'vue';

const {OWNER} = MEMBER_ROLE;

const getDefaultState = () => ({
  activeWorkspaceId: null,
  activeWorkspace: null,
  invitation: {
    workspaceName: null,
    workspacePhoto: null,
  },
});

const mutations = {
  SET_ACTIVE_WORKSPACE(state, workspace) {
    state.activeWorkspace = workspace;
  },
  SET_ACTIVE_WORKSPACE_ID(state, id) {
    state.activeWorkspaceId = id;
  },
  SET_INVITATION(state, {workspaceName, workspacePhoto}) {
    state.invitation.workspaceName = workspaceName;
    state.invitation.workspacePhoto = workspacePhoto;
  },
  CLEAR_INVITATION(state) {
    Vue.set(state, 'invitation', getDefaultState().invitation);
  },
};

const getters = {
  activeWorkspace(state) {
    return state.activeWorkspace;
  },
};

const actions = {
  async create({rootState, dispatch}, {photo, ...variables}) {
    const {user} = rootState.auth;
    variables.members = {
      data: {
        user_id: user.id,
        invitation_confirmed: true,
        role: OWNER,
      },
    };
    const params = {
      mutation: insertUserWorkspace,
      variables: {changes: variables},
    };
    const {data} = await apolloClient.mutate(params);
    const workspace = data.workspace.returning[0];
    if (photo) {
      await dispatch('updateLogo', {id: workspace.id, photo});
    }
  },
  async edit({dispatch}, {id, photo, ...changes}) {
    const params = {
      mutation: updateUserWorkspace,
      variables: {id, changes},
    };
    const {data} = await apolloClient.mutate(params);
    const workspace = data.workspace.returning[0];
    if (photo && photo !== workspace.photo) {
      await dispatch('updateLogo', {id, photo});
    }
  },
  async remove(_, id) {
    const params = {
      mutation: updateUserWorkspace,
      variables: {id, changes: {active: false}},
    };
    await apolloClient.mutate(params);
  },
  async updateLogo({rootState}, {id, photo, onlyFromFirebase = false}) {
    const {user} = rootState.auth;
    const storageRef = firebase.storage()
        .ref(`users/${user.id}/workspace_picture/profile_${id}.jpg`);
    let photoUrl = null;
    if (photo === null) {
      await storageRef.delete();
    } else {
      const task = await storageRef.putString(photo, 'data_url');
      photoUrl = await task.ref.getDownloadURL();
    }
    if (!onlyFromFirebase) {
      await apolloClient.mutate({
        mutation: updateUserWorkspace,
        variables: {changes: {photo: photoUrl}, id},
      });
    }
  },
};
export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  getDefaultState,
  state: getDefaultState(),
};
