import gql from 'graphql-tag';
import {getIntegrationTaskDetailLink} from '@/utils/time-entry';
const name = 'integration_app';
export default {
  name,
  resolvers: {
    nonce: ({id, nonce}, _args, {cache}) => {
      if (!nonce) {
        const fragment = gql`
        fragment nonceToGet on integration_app {
          nonce
        }
      `;
        let integration;
        try {
          integration = cache.readFragment({
            fragment, id: `${name}:${id}`,
          });
        } catch (e) {}
        return integration ? integration.nonce : null;
      }
      return nonce || null;
    },
    task_link: (integration) =>{
      return getIntegrationTaskDetailLink(integration);
    },
  },
  mutations: {
    updateNonce: (_, {id, nonce}, {cache}) => {
      cache.writeData({id: `${name}:${id}`, data: {nonce}});
    },
  },
};
