// TODO: move this function to another module
export const formatError = (code) => {
  // Replace - with _
  // and split by / because firebase errors come as auth/some-error
  const chunks = code.replace(/-/g, '_')
      .split(/\/(.+)/);
  // Remove . from chunks because the translation will throw an error otherwise
  const [module, error] = chunks.map((value)=>value.replace(/\./g, ''));
  return `error.${error ? error : module || 'default'}`;
};
