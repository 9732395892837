<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 4L6.5 7M6.5 11L6.5 20"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 4L12.5 13M12.5 17L12.5 20"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5 4L18.5 6M18.5 10L18.5 20"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="6.5"
      cy="9"
      r="1.75"
      stroke="white"
      stroke-width="1.5"
    />
    <circle
      cx="12.5"
      cy="15"
      r="1.75"
      stroke="white"
      stroke-width="1.5"
    />
    <circle
      cx="18.5"
      cy="8"
      r="1.75"
      stroke="white"
      stroke-width="1.5"
    />
  </svg>
</template>
<script>
export default {};
</script>
