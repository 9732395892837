import {replaceUrlParameters} from '@/utils/modal';
import {getIntegrationTaskDetailLink} from '@/utils/time-entry';
import moment from 'moment';

const name = 'task';
export default {
  name,
  resolvers: {
    calendar_due_date: ({due_date})=>{
      return due_date ? moment(due_date).calendar() : '';
    },
    project_id: ({project_integration})=>{
      if (!project_integration) return null;
      return project_integration.project_id;
    },
    remaining: ({time_estimate, time_spent})=>{
      const progress = (time_spent / time_estimate) * 100;
      return {
        progress,
        overflow: Math.min(progress, 100),
        difference: Math.abs(time_estimate - time_spent),
      };
    },
    overdue: ({due_date, completed}) => {
      if (completed || !due_date) return false;
      const yesterday = moment().startOf('day');
      return yesterday.toDate() > new Date(due_date);
    },
    integration_link: ({integration_task_id, project_integration})=> {
      const taskDetailLink = getIntegrationTaskDetailLink(
          project_integration.integration
      );
      return replaceUrlParameters(
          taskDetailLink,
          {
            task_id: integration_task_id,
            project_id: project_integration.integration_project_id,
          }
      );
    },
    assigned_users: ({assignees})=>{
      return assignees.map((member)=>member.user);
    },
  },
};
