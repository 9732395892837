import {TIMELINE_INTERVAL_MILLISECONDS} from '@/utils/time-entry';
import {
  getDateAsString,
  getDateEnd,
  getDateStart,
  MIN_WORK_HOURS,
} from '@/utils/time';
import moment from 'moment';

export const TIMELINE_STATUSES = {
  BLUR: 'blur',
  DETAIL_DIALOG_OPEN: 'detail-dialog-open',
  DRAGGING: 'dragging',
};

export const calculateTimeRangeWithMinHours = (timeRange, minHours)=>{
  if (minHours > 24) throw new Error('The min hours should be up to 24 hours');

  const startDate = moment(timeRange.startDate);
  const endDate = moment(timeRange.endDate);
  const hoursDifference = endDate.diff(startDate, 'hours');

  if (hoursDifference >= minHours) return timeRange;
  if (hoursDifference < 0) throw new Error(`The start date "${startDate.format()}" is higher than end date "${endDate.format()}"`);

  const endOfDay = moment(startDate).endOf('day');
  const endDateAtStartOfHour = endDate.startOf('hour');
  const hoursUntilEndOfDay = endOfDay.diff(endDateAtStartOfHour, 'hours');

  const hoursToAdd = minHours - hoursDifference;
  const upperHoursToAdd = Math.min(hoursUntilEndOfDay, hoursToAdd);
  const lowerHoursToAdd = hoursToAdd - upperHoursToAdd;

  endDate.add(upperHoursToAdd, 'hours');
  startDate.add(-lowerHoursToAdd, 'hours');

  return {startDate: startDate.format(), endDate: endDate.format()};
};

const getDateArrayWithoutNullValues = (dates)=>dates
    .filter((date)=>!!date)
    .map((date)=>new Date(date));

const getDefaultState = ()=> {
  const today = new Date();
  return {
    eventId: null,
    status: TIMELINE_STATUSES.BLUR,
    filter: {
      date: getDateAsString(today),
    },
    timeEntryLimit: {
      isSet: false,
      startDate: null,
      endDate: null,
    },
    timeIntervalLimit: {
      isSet: false,
      startDate: null,
      endDate: null,
    },
    workDayLimit: {
      isSet: false,
      startDate: null,
      endDate: null,
    },
  };
};

const ALLOWED_TIMELINE_TRANSITIONS = {
  [TIMELINE_STATUSES.BLUR]: [
    TIMELINE_STATUSES.DETAIL_DIALOG_OPEN,
    TIMELINE_STATUSES.DRAGGING,
  ],
  [TIMELINE_STATUSES.DETAIL_DIALOG_OPEN]: [
    TIMELINE_STATUSES.BLUR,
    TIMELINE_STATUSES.DETAIL_DIALOG_OPEN,
  ],
  [TIMELINE_STATUSES.DRAGGING]: [
    TIMELINE_STATUSES.DRAGGING,
    TIMELINE_STATUSES.DETAIL_DIALOG_OPEN,
    TIMELINE_STATUSES.BLUR,
  ],
};

export const isTimelineTransitionAllowed = (currentStatus, nextStatus) =>
  ALLOWED_TIMELINE_TRANSITIONS[nextStatus].includes(currentStatus);

const mutations = {
  SET_BLUR_EVENT(state) {
    state.eventId = null;
    state.status = TIMELINE_STATUSES.BLUR;
  },
  OPEN_DETAIL_DIALOG(state, {eventId}) {
    state.eventId = eventId;
    state.status = TIMELINE_STATUSES.DETAIL_DIALOG_OPEN;
  },
  START_DRAGGING(state, {eventId}) {
    state.eventId = eventId;
    state.status = TIMELINE_STATUSES.DRAGGING;
  },
  SET_FILTER_DATE(state, {date}) {
    state.filter.date = date;
    const {timeEntryLimit, timeIntervalLimit} = getDefaultState();
    state.timeEntryLimit = timeEntryLimit;
    state.timeIntervalLimit = timeIntervalLimit;
  },
  SET_TIME_ENTRY_LIMITS(state, {startDate, endDate}) {
    state.timeEntryLimit.isSet = true;
    state.timeEntryLimit.startDate = startDate;
    state.timeEntryLimit.endDate = endDate;
  },
  SET_TIME_INTERVAL_LIMITS(state, {startDate, endDate}) {
    state.timeIntervalLimit.isSet = true;
    state.timeIntervalLimit.startDate = startDate;
    state.timeIntervalLimit.endDate = endDate;
  },
  SET_WORKDAY_LIMITS(state, {startDate, endDate}) {
    state.workDayLimit.isSet = true;
    state.workDayLimit.startDate = startDate;
    state.workDayLimit.endDate = endDate;
  },
};

const actions = {
  dispatchStatusChange({commit, state}, payload) {
    const mutationMap = {
      [TIMELINE_STATUSES.BLUR]: 'SET_BLUR_EVENT',
      [TIMELINE_STATUSES.DETAIL_DIALOG_OPEN]: 'OPEN_DETAIL_DIALOG',
      [TIMELINE_STATUSES.DRAGGING]: 'START_DRAGGING',
    };
    if (isTimelineTransitionAllowed(state.status, payload.status)) {
      commit(mutationMap[payload.status], payload);
    }
  },
  setFilterDate({commit}, payload) {
    commit('SET_BLUR_EVENT');
    commit('SET_FILTER_DATE', payload);
  },
};

const getters = {
  detailDialogStatus({status, eventId}) {
    const isOpen = status === TIMELINE_STATUSES.DETAIL_DIALOG_OPEN;
    return {isOpen, eventId};
  },
  timePeriod(state) {
    const date = moment(state.filter.date).toDate();
    return {startDate: getDateStart(date), endDate: getDateEnd(date)};
  },
  visibleTimePeriod(state, getters) {
    const {timeEntryLimit, timeIntervalLimit, workDayLimit} = state;
    const startDateArray = getDateArrayWithoutNullValues([
      timeEntryLimit.startDate,
      timeIntervalLimit.startDate,
      workDayLimit.startDate,
    ]);
    const endDateArray = getDateArrayWithoutNullValues([
      timeEntryLimit.endDate,
      timeIntervalLimit.endDate,
      workDayLimit.endDate,
    ]);

    if (!getters.isVisibleTimePeriodSet || !startDateArray.length || !endDateArray.length) {
      return {startDate: null, endDate: null};
    }

    const startDate = moment(Math.min(...startDateArray));
    const endDate = moment(Math.max(moment(state.filter.date).toDate()));

    startDate.set({hour: 0, minute: 0, second: 0, milliseconds: 0});
    endDate.set({hour: 23, minute: 59, second: 0, milliseconds: 0});

    const visibleRange = {
      startDate: startDate.format(),
      endDate: endDate.format(),
    };
    return calculateTimeRangeWithMinHours(visibleRange, MIN_WORK_HOURS);
  },
  isVisibleTimePeriodSet(state) {
    const {timeEntryLimit, timeIntervalLimit, workDayLimit} = state;
    return timeEntryLimit.isSet && timeIntervalLimit.isSet && workDayLimit.isSet;
  },
  totalVisibleIntervals(_, getters) {
    if (!getters.isVisibleTimePeriodSet) {
      return 0;
    }
    const {visibleTimePeriod} = getters;
    const startDate = new Date(visibleTimePeriod.startDate);
    const endDate = new Date(visibleTimePeriod.endDate);
    return (endDate - startDate) / TIMELINE_INTERVAL_MILLISECONDS;
  },
  totalStartOffset(_, getters) {
    if (!getters.isVisibleTimePeriodSet) {
      return 0;
    }
    const {timePeriod, visibleTimePeriod} = getters;
    const dayStart = new Date(timePeriod.startDate);
    const startDate = new Date(visibleTimePeriod.startDate);
    return (startDate - dayStart) / TIMELINE_INTERVAL_MILLISECONDS;
  },
  noDataAvailable(state) {
    return !state.timeEntryLimit.startDate &&
      !state.timeIntervalLimit.startDate;
  },
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  getDefaultState,
  state: getDefaultState(),
};
