import {getInitials} from '@/utils/user';
import store from '@/store';
import i18n from '@/plugins/i18n';
import {MEMBER_ROLE} from '@/utils/enums';
import {mapSubjectSettings} from '@/utils/settings';
import {lightenColor} from '@/utils/colors';
const {OWNER} = MEMBER_ROLE;
const name = 'workspace';
export default {
  name,
  resolvers: {
    initials: ({name}) => {
      return getInitials(name);
    },
    owner_name: ({members}) => {
      if (!members) return null;
      const owner = members.find((member)=>member.role === OWNER);
      return owner.user.id === store.state.auth.user.id ?
        i18n.t('me') :
        owner.user.full_name;
    },
    current_member: ({members}) =>{
      if (!members) return null;
      return members.find(
          (member)=>member.user.id === store.state.auth.user.id);
    },
    formatted_settings: (workspace)=>{
      const defaults = {work_start: '08:00', work_end: '12:00', minimum_hours: 8};
      const settings = mapSubjectSettings(workspace);
      return Object.assign({}, defaults, settings);
    },
    lighter_color({color}) {
      return lightenColor(color, 15);
    },
    hover_color({color}) {
      return lightenColor(color, -10);
    },
  },
};
