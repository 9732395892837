import INTEGRATION_APIS from '@/services/IntegrationApis';
import {getUserIntegrations} from '@/graphql/integration_user.gql';
import apolloClient from '@/apollo/client';
import store from '@/store';
import {keyBy} from 'lodash';
import Vue from 'vue';
import TranslatableError from '@/utils/errors/TranslatableError';
const handler = {
  get: async function(obj, prop) {
    if (obj[prop]) return obj[prop];
    const userIntegrations = await apolloClient.query({
      query: getUserIntegrations,
      variables: {id: store.state.auth.user.id},
    }).then(
        ({data})=>
          keyBy(data.userIntegrations,
              ((userIntegration)=>userIntegration.app.name)));
    if (!userIntegrations[prop]) {
      // eslint-disable-next-line max-len
      throw new TranslatableError('missing_personal_integration', [prop]);
    }
    const {api_url} = userIntegrations[prop].app;
    const {id, token} = userIntegrations[prop];
    const API_CONSTRUCTOR = INTEGRATION_APIS[prop];
    obj[prop] = new API_CONSTRUCTOR(api_url, token, id);
    return obj[prop];
  },
};

const IntegrationClientPlugin = {
  install(Vue) {
    Vue.prototype.$integrations = {
      apis: new Proxy({}, handler),
      clear() {
        this.apis = new Proxy({}, handler);
      },
    };
  },
};

Vue.use(IntegrationClientPlugin);
