import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {I18nFormatter} from '@/utils/i18n/I18nFormatter';
import {getLanguage} from '@/utils/i18n';

Vue.use(VueI18n);

/**
 * This function to load all the messages per locale,
 * which is used by the VueI18n plugin.
 *
 * @return {string} A good string
 */
function loadLocaleMessages() {
  const locales = require.context(
      '@/locales',
      true,
      /[A-Za-z0-9-_,\s]+\.js$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

export default new VueI18n({
  locale: getLanguage(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  formatter: new I18nFormatter(),
  missing: (locale, key, vm) => {
    if (!key.includes('$vuetify') &&
        key.includes('error') &&
        key.includes('.')) {
      return vm.$t(`error.default`);
    }
    return key;
  },
});
