import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VSnackbar,{attrs:{"timeout":_vm.timeout,"color":"navy_blue.lighten2","multi-line":""},model:{value:(_vm.enable),callback:function ($$v) {_vm.enable=$$v},expression:"enable"}},[_c(VRow,{attrs:{"align":"center","justify":"space-between"}},[_c('div',{staticStyle:{"width":"64px","height":"64px"}},[_c(VImg,{attrs:{"src":require('@/assets/+tracker pet.svg'),"width":"64","height":"64","contain":""}})],1),_c(VRow,{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VRow,{staticClass:"subtitle-2 white--text",attrs:{"align":"center","no-gutters":""}},[_c(VIcon,{staticClass:"mr-1",attrs:{"color":"white"}},[_vm._v(" mdi-information-outline ")]),_vm._v(" New Version available! ")],1),_c('h5',{staticClass:"pa-0 ml-1 "},[_vm._v(" Refresh to use the latest version. ")])],1)],1),_c(VBtn,{staticClass:"pa-0 ml-2 text-capitalize",attrs:{"color":"accent_blue","retain-focus-on-click":"","ripple":false},on:{"click":_vm.refreshApp}},[_vm._v(" Refresh ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }