import {Ability, createAliasResolver} from '@casl/ability';
import {subjectTypeFromGraphql} from '@/permissions/ability';
import {loadWorkspacePermissions} from '@/permissions/index';
import store from '@/store';
import {ACTIONS_ALIASES} from '@/utils/enums';

let cachedAbilities = {};

/**
 * Builds the workspace ability given a workspace, if the ability is cached
 * returns the cached ability instead.
 * @param {Int} workspaceId - The workspace id
 * @param {Object} current_member - The member record correspondent
 * to the logged in user
 * @return {Ability} - The ability object for the provided workspace
 */
export function getWorkspaceAbility({id, current_member}) {
  let ability;
  if (cachedAbilities[id]) {
    ability = cachedAbilities[id];
  } else {
    ability = new Ability([],
        {
          resolveAction: createAliasResolver(ACTIONS_ALIASES),
          subjectName: subjectTypeFromGraphql,
        }
    );
    const {user} = store.state.auth;
    const permissions = loadWorkspacePermissions(user, current_member.role);
    ability.update(permissions);
    cachedAbilities[id] = ability;
  }
  return ability;
}

/**
 * Returns the cached ability for a workspace given a workspaceId

 * @param {Int} workspaceId
 * @return {Ability}
 */
export function getCachedWorkspaceAbilityById(workspaceId) {
  return cachedAbilities[workspaceId];
}

/**
 * Clears the workspaces abilities cache entirely
 */
export function clearAbilityCache() {
  cachedAbilities = {};
}

/**
 * Removes the cached ability of a workspace given a workspaceId
 * @param {Int} workspaceId - The id of the workspace to remove from the
 * ability cache
 */
export function clearAbilityCacheById(workspaceId) {
  delete cachedAbilities[workspaceId];
}
