import moment from 'moment-timezone';
import {MONTHS} from '@/utils/time';
import {
  buildVariables,
  DATE_STATUS, formattedDateQuery, getMonthDifference,
  GROUP_BY_FILTER_DATE, GROUP_BY_MAP, MAX_MONTHS_LIMIT,
  MIN_NUMBER_DATE,
  SERIE_COLOR,
} from '@/utils/dashboardView';
import apolloClient from '@/apollo/client';
import {get_time_tracked} from '@/graphql/time_tracked.gql';
import {round} from '@/utils/number';
import i18n from '@/plugins/i18n';
import debounce from 'lodash/debounce';

// X Axis
const getFormattedDateForXAxis = (date) =>{
  return {day: date.format('ddd').toLowerCase(), monthDay: date.format('MMM D')};
};

const differenceDays = (range)=>{
  const [lowerLimit, , upperLimit] = range.split(' ');

  return moment(upperLimit).diff(lowerLimit, 'days');
};

const getDateRangeLabels = (range) =>{
  const [lowerLimit, , upperLimit] = range.split(' ');
  let labels = null;

  if (differenceDays(range) <= MIN_NUMBER_DATE) {
    labels = getDateRange(lowerLimit, MIN_NUMBER_DATE).map((date) => getFormattedDateForXAxis(date));
  } else if (differenceDays(range) > MIN_NUMBER_DATE && getMonthDifference(range) < 1) {
    const firstDay = moment(lowerLimit).format();
    const maxDays = moment(upperLimit).daysInMonth();

    labels = getDateRange(firstDay, maxDays).map((dateR) => getFormattedDateForXAxis(dateR));
  } else {
    const isMonths = moment(upperLimit).diff(lowerLimit, 'months', true);
    const firstDay = moment(lowerLimit).format();
    const maxDays = moment(upperLimit).format();
    if (isMonths <= MAX_MONTHS_LIMIT) {
      labels = getMonthsRange(firstDay, maxDays).map((month)=> moment(month).format('MMM YYYY').toLowerCase());
    } else {
      labels = getYearRange(moment(lowerLimit).format('YYYY'), moment(upperLimit).format('YYYY'));
    }
  }
  return labels;
};

const getYearRange = (firstYear, lastYear) =>{
  const labels = [];
  let count = 1;
  labels.push(firstYear);
  while (Number(firstYear) + count < lastYear || labels.length < MIN_NUMBER_DATE) {
    labels.push((Number(firstYear) + count).toString());
    count++;
  }
  return labels;
};

const getDateRange = (firstDay, maxDays) =>{
  const labels = [];
  let count = 1;
  labels.push(moment(firstDay));
  while (count < maxDays) {
    labels.push(moment(firstDay).add(count, 'days'));

    count++;
  }
  return labels;
};

const getMonthsRange = (firstDay, maxDays) =>{
  let dateStart = moment(firstDay);
  const dateEnd = moment(maxDays);
  const timeValues = [];

  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M') || timeValues.length < MAX_MONTHS_LIMIT) {
    timeValues.push(dateStart.format('YYYY-MM'));
    dateStart = dateStart.add(1, 'month');
  }
  return timeValues;
};

const setLabelsXAxis = (data) =>{
  let labels = [];
  switch (data.period) {
    case DATE_STATUS.week:
      const splitWeek = data.date.split(' ')[2];
      const firstDayOfWeek = moment(splitWeek).startOf('isoWeek').format();
      labels = getDateRange( firstDayOfWeek, MIN_NUMBER_DATE).map((date) => getFormattedDateForXAxis(date));
      break;
    case DATE_STATUS.month:
      const firstDay = moment(data.date).startOf('month').format();
      const maxDays = moment(data.date).daysInMonth();

      labels = getDateRange(firstDay, maxDays).map((date) => getFormattedDateForXAxis(date));
      break;
    case DATE_STATUS.year:
      labels = MONTHS;
      break;
    case DATE_STATUS.date_range:
      labels = getDateRangeLabels(data.date);
      break;
    default:
      const today = moment(data.date).startOf('isoWeek').format();

      labels = getDateRange(today, MIN_NUMBER_DATE).map((date) => getFormattedDateForXAxis(date));
  }

  return labels;
};

// Y axis
const getAccumulatedTimeTrackedResults = (data, selectedDate)=>{
  let accumTimeTracked = [];

  for (const time of data.timeTracked) {
    let accumTime = false;
    if (accumTimeTracked.length === 0) {
      accumTimeTracked.push(time);
    } else {
      for (const accumTimeTrackedRows of accumTimeTracked) {
        const sameProperty = accumTimeTrackedRows[GROUP_BY_FILTER_DATE[selectedDate.property]] === time[GROUP_BY_FILTER_DATE[selectedDate.property]];
        if (accumTimeTrackedRows[GROUP_BY_MAP[data.groupBy].nameKey] === time[GROUP_BY_MAP[data.groupBy].nameKey] && sameProperty) {
          accumTimeTrackedRows.hours = time.sum.hours + accumTimeTrackedRows.hours;
          accumTime = true;
        }
      }
      if (!accumTime) {
        accumTimeTracked.push(time);
      }
    }
  }

  accumTimeTracked = accumTimeTracked.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  return buildSeriesYaxis({data, timeTracked: accumTimeTracked}, selectedDate);
};

const getDateRangePeriodValidations = (date)=>{
  const isPeriodMonths = differenceDays(date) > MIN_NUMBER_DATE && getMonthDifference(date) < 1;
  const isPeriodYears = getMonthDifference(date) <= MAX_MONTHS_LIMIT && getMonthDifference(date) > 1;
  const isPeriodDays = getMonthDifference(date) < 1;
  const isPeriodYearRange = getMonthDifference(date) > MAX_MONTHS_LIMIT;

  return {isPeriodMonths, isPeriodYears, isPeriodDays, isPeriodYearRange};
};

const getTimeOrRangeValues = (timeTracked, range) =>{
  if (timeTracked.length < range.length) {
    return range;
  }
  return timeTracked;
};

const getFilteredDate = (timeTracked, selectedDate)=>{
  let filteredDate = null;
  let firstDay = null;
  let maxDays = null;
  let getRange = [];
  let timeTrackedDateValues = null;
  let periodValidations = {
    isPeriodMonths: false,
    isPeriodYears: false,
    isPeriodDays: false,
    isPeriodYearRange: false,
  };

  const isDateRange = selectedDate.period === DATE_STATUS.date_range;

  if (isDateRange) {
    periodValidations = getDateRangePeriodValidations(selectedDate.date);
  }

  if (periodValidations.isPeriodYears) {
    timeTrackedDateValues = timeTracked.map((time) =>{
      return `${time.month_name.split(' ').join('')} ${time.year_actual}`;
    });

    timeTrackedDateValues = Array.from(new Set(timeTrackedDateValues)).sort((a, b) => {
      return moment(a) - moment(b);
    });
  } else {
    timeTrackedDateValues = Array.from(new Set(timeTracked.map((item) => item[GROUP_BY_FILTER_DATE[selectedDate.property]])));
  }

  if (selectedDate.period === DATE_STATUS.month) {
    firstDay = moment(selectedDate.date).startOf('month').format();
    maxDays = moment(selectedDate.date).daysInMonth();
    getRange = getDateRange(firstDay, maxDays).map((item) => moment.utc(item).startOf('day').format());
    filteredDate = getTimeOrRangeValues(timeTrackedDateValues, getRange);
  } else if (periodValidations.isPeriodMonths) {
    const date = selectedDate.date.split(' ');
    getRange = getDateRange(date[0], date[2]).map((item) => moment.utc(item).format());
    filteredDate = getTimeOrRangeValues(timeTrackedDateValues, getRange);
  } else if (selectedDate.period === DATE_STATUS.year) {
    filteredDate = timeTrackedDateValues.length < MONTHS.length ? MONTHS.map((month) => moment().month(month).format('MMMM')) : timeTrackedDateValues;
  } else if (periodValidations.isPeriodYears) {
    const startDay = timeTrackedDateValues[0];
    const lastDay = timeTrackedDateValues[timeTrackedDateValues.length - 1];

    filteredDate = getMonthsRange(startDay, lastDay);
  } else if (selectedDate.period === DATE_STATUS.today || selectedDate.period === DATE_STATUS.week) {
    let splitDay = selectedDate.date.split(' ')[0];
    splitDay = moment(splitDay).startOf('isoweek').format();
    getRange = getDateRange(splitDay, MIN_NUMBER_DATE).map((item) => moment.utc(item).startOf('day').format());
    filteredDate = getTimeOrRangeValues(timeTrackedDateValues, getRange);
  } else if (periodValidations.isPeriodDays) {
    const splitDay = selectedDate.date.split(' ')[0];
    getRange = getDateRange(splitDay, MIN_NUMBER_DATE).map((item) => moment.utc(item).startOf('day').format());
    filteredDate = getTimeOrRangeValues(timeTrackedDateValues, getRange);
  } else if (periodValidations.isPeriodYearRange) {
    const splitDay = selectedDate.date.split(' ');
    firstDay = moment(splitDay[0]).format('YYYY');
    const lastDay = moment(splitDay[2]).format('YYYY');

    filteredDate = timeTrackedDateValues.length < getYearRange(firstDay, lastDay).length ? getYearRange(firstDay, lastDay) : timeTrackedDateValues;
  }

  return filteredDate;
};

const buildSeries = (names)=>{
  const series = [];
  for (const name of names) {
    series.push({
      name: name,
      type: 'bar',
      stack: '1',
      smooth: true,
      itemStyle: {
        color: undefined,
      },
      emphasis: {
        focus: 'series',
      },
      data: [],
    });
  }

  series.push({
    name: 'TIME EFFORT',
    type: 'line',
    stack: '1',
    smooth: true,
    itemStyle: {color: '#8c0101'},
    tooltip: {
      show: false,
    },
    emphasis: {
      focus: 'series',
    },
    data: [],
  });
  return series;
};

const buildSeriesYaxis = ({data, timeTracked}, selectedDate)=>{
  const filteredDate = getFilteredDate(timeTracked, selectedDate);
  const filteredPrincipalNames = Array.from(new Set(timeTracked.map((item) => item[GROUP_BY_MAP[data.groupBy].nameKey])));
  let series = buildSeries(filteredPrincipalNames);

  const isDateRange = selectedDate.period === DATE_STATUS.date_range;

  for (let datePos = 0; datePos < filteredDate.length; datePos++) {
    let totalHoursByDate = 0;
    for (const serie of timeTracked) {
      const position = filteredPrincipalNames.findIndex((name) =>
        name === serie[GROUP_BY_MAP[data.groupBy].nameKey]
      );
      const isNotSerieNumberDate = isNaN(serie[GROUP_BY_FILTER_DATE[selectedDate.property]]);

      if (isEqualTimePeriod(filteredDate[datePos], serie[GROUP_BY_FILTER_DATE[selectedDate.property]])) {
        totalHoursByDate += serie.sum.hours;
        series[position].data.push({value: serie.sum.hours, tooltip: {badgeContent: 0}});
        series[position].itemStyle.color = serie[SERIE_COLOR[GROUP_BY_MAP[data.groupBy].nameKey]] || undefined;
      } else if (isDateRange && isNotSerieNumberDate) {
        const builderDate = moment(`${serie[GROUP_BY_FILTER_DATE[selectedDate.property]].split(' ').join('')} ${serie.year_actual}`).format('YYYY-MM');
        if (filteredDate[datePos] === builderDate) {
          totalHoursByDate += serie.sum.hours;
          series[position].data.push({value: serie.sum.hours, tooltip: {badgeContent: 0}});
          series[position].itemStyle.color = serie[SERIE_COLOR[GROUP_BY_MAP[data.groupBy].nameKey]] || undefined;
        }
      }
    }

    series = setTotalHoursByDate(series, {pos: datePos, total: totalHoursByDate});
  }

  return series;
};

const setTotalHoursByDate = (array, {pos, total}) =>{
  for (const serie of array) {
    if (pos < serie.data.length) {
      serie.data[pos].tooltip.badgeContent = `${((serie.data[pos].value / total) * 100).toFixed(2)}%`;
    }
    if (serie.data[pos] === undefined) {
      serie.data[pos] = 0;
    }
  }
  return array;
};


const isEqualTimePeriod = (timePeriod1, timePeriod2)=>{
  const timePeriodValid1 = moment(timePeriod1).isValid();
  const timePeriodValid2 = moment(timePeriod2).isValid();

  const isSameDate = timePeriodValid1 && timePeriodValid2 && moment(timePeriod1).format() === moment(timePeriod2).format();
  const isSameYear = Number(timePeriod1) === timePeriod2;
  const isSameMonth = isNaN(timePeriod1) && isNaN(timePeriod2) && timePeriod1 === timePeriod2;

  return isSameDate || isSameYear || isSameMonth;
};

const getDefaultState = ()=>( {
  dateSelected: {date: moment().startOf('days').format(), period: DATE_STATUS.today},
  timeTracked: [],
  isTimeTracked: false,
  dateRangePeriodSelected: '',
  groupBy: 'project',
});

const allowTimeTracked = (timeTracked, period)=>{
  if (timeTracked.length === 0) {
    return true;
  }

  return timeTracked.some((time) => !time[GROUP_BY_FILTER_DATE[period]]);
};

const mutations = {
  SET_SELECTED_DATE(state, payload) {
    state.dateSelected = payload;
    state.timeTracked = [];
  },
  SET_TIME_TRACKED(state, payload) {
    state.timeTracked = payload;
  },
  SET_GROUP_BY(state, payload) {
    state.groupBy = payload;
  },
};

const getters = {
  VISIBLE_X_AXIS(state) {
    return setLabelsXAxis(state.dateSelected);
  },

  VISIBLE_Y_AXIS(state) {
    let propertyMatch = '';
    if (state.dateSelected.period === DATE_STATUS.date_range) {
      if (state.dateRangePeriodSelected !== GROUP_BY_FILTER_DATE[DATE_STATUS.date_range]) {
        propertyMatch = state.dateRangePeriodSelected;
      } else {
        propertyMatch = state.dateSelected.period;
      }
    } else {
      propertyMatch = state.dateSelected.period;
    }

    if (allowTimeTracked(state.timeTracked, propertyMatch)) {
      return [];
    }
    const selectedDate = {date: state.dateSelected.date, property: propertyMatch, period: state.dateSelected.period};
    return getAccumulatedTimeTrackedResults(state, selectedDate);
  },

  translatedTimeTracked(state) {
    const emptyLabelsTranslations = {
      project_name: {
        emptyLabel: 'No Project',
        translation: i18n.t('no_project'),
      },
      client_name: {
        emptyLabel: 'No Client',
        translation: i18n.t('no_client'),
      },
      task_name: {
        emptyLabel: 'No Task',
        translation: i18n.t('no_task'),
      },
    };
    return state.timeTracked.map((timeTrackedRow)=>{
      const translatedTimeTrackedRow = {...timeTrackedRow};
      for (const [keyField, {emptyLabel, translation}] of Object.entries(emptyLabelsTranslations)) {
        translatedTimeTrackedRow[keyField] = translatedTimeTrackedRow[keyField] === emptyLabel ? translation : translatedTimeTrackedRow[keyField];
      }
      return translatedTimeTrackedRow;
    });
  },
};

const actions = {
  async getTimeTracked({commit, state}, payload) {
    const workspace_id = payload.workspaceId;
    let selectedGroupBy = [];
    const filters = payload.filters;
    const timeZone = moment.tz.guess() || 'UTC';
    const date = formattedDateQuery(state.dateSelected.date, state.dateSelected.period);
    selectedGroupBy = [...GROUP_BY_MAP[state.groupBy].groupByFields, date.groupBy];

    if (state.dateSelected.period === DATE_STATUS.date_range) {
      if (date.groupBy !== GROUP_BY_FILTER_DATE[DATE_STATUS.date_range]) {
        const property = date.groupBy === GROUP_BY_FILTER_DATE.today ? DATE_STATUS.today : DATE_STATUS.year;
        state.dateRangePeriodSelected = property;
        if (property === DATE_STATUS.year) {
          selectedGroupBy = [...GROUP_BY_MAP[state.groupBy].groupByFields, date.groupBy, GROUP_BY_FILTER_DATE[DATE_STATUS.date_range]];
        }
      } else {
        state.dateRangePeriodSelected = GROUP_BY_FILTER_DATE[DATE_STATUS.date_range];
      }
    }

    const variables = {
      workspace_id,
      filters,
      timeZone,
      start: date.start,
      end: date.end,
      groupBy: selectedGroupBy,
    };

    const params = {
      mutation: get_time_tracked,
      variables: buildVariables(variables),
    };

    try {
      let roundedTimeTracked = [];
      let data;

      debounce(async function() {
        data = await apolloClient.mutate(params);

        if (data?.data) {
          roundedTimeTracked = data.data.time_tracked.data.map((timeTrackedRow)=>{
            return {
              ...timeTrackedRow,
              sum: {
                hours: round(Number(timeTrackedRow.sum.hours), 2),
              },
            };
          });
        }
        state.isTimeTracked = roundedTimeTracked.length > 0;
        commit('SET_TIME_TRACKED', roundedTimeTracked);
      }, 500)();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  },
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  getters,
  actions,
  getDefaultState,
};
