import {NotFoundError} from '@/utils/errors/NotFoundError';
/**
 * Middleware to verify if the user has permission to visit the page
 * @param {Function} validator function, must return true if
 * the user is allowed to visit the page or false if not;
 * @return {Function} - Middleware
 **/
export function permission(validator) {
  return async function({to, next}) {
    try {
      const isAllowed = await validator(to);
      if (!isAllowed) {
        return next('/forbidden');
      }
    } catch (e) {
      if (e instanceof NotFoundError) {
        next('/not-found');
      } else {
        throw e;
      }
    }
    return next();
  };
}
