import moment from 'moment';
/**
 * Function to convert human readable time (Example: "8h 30m") into milliseconds
 * @param {string} humanDuration
 * @return {number} duration in milliseconds
 */
export function formatHumanDuration(humanDuration) {
  const VALID_UNITS = ['s', 'y', 'M', 'w', 'd', 'h', 'm'];
  const validator = new RegExp(`\\d+\\s*(${VALID_UNITS.join('|')})`, 'g');
  const tokens = humanDuration.match(validator);
  if (!Array.isArray(tokens)) {
    throw new Error(`Incorrect format human duration format ${humanDuration}`);
  }
  let duration = moment.duration();
  for (const token of tokens) {
    const [magnitude, unit] = token.match(/\d+|\D+/g);
    duration = duration.add(parseInt(magnitude), unit);
  }
  if (!duration.isValid()) {
    throw new Error(`Incorrect format human duration format ${humanDuration}`);
  }

  return duration.asMilliseconds();
}

/**
 *  Receives a date and parse it to a iso string only with day, month and year
 * @param {Date} date - Date to parse to ISO 8601
 * @return {string} Formatted date
 */
export function getDateAsString(date) {
  return moment(date).format().substring(0, 10);
}

/**
 *  Receives a date and transforms it to an ISO 8601 string
 * @param {Date} date - Date
 * @return {string} Formatted date
 */
export function dateToCleanISO8601String(date) {
  return cleanISO8601Date(moment(date).format());
}
/**
 *  Receives a date as string in the ISO 8601 format
 *  and returns a short date iso string without any "-" or ":" characters
 *  including year, month, day, hours and minutes
 * @param {string} date - Date to parse to ISO 8601
 * @return {string} Formatted date
 */
export function cleanISO8601Date(date) {
  return date.substring(0, 16).replace(/:/g, '').replace(/-/g, '');
}

/**
 *  Receives a date and set it to the beginning of that day at local time
 * @param {Date} date - Date
 * @return {Date} Date at the start of the day
 */
export function getDateStart(date) {
  const startDate = new Date(date.getTime());
  startDate.setHours(0, 0, 0, 0);
  return startDate;
}

/**
 *  Receives a date and set it to the end of that day at local time
 * @param {Date} date - Date
 * @return {Date} Date at the end of the day
 */
export function getDateEnd(date) {
  const startDate = getDateStart(date);
  startDate.setDate(startDate.getDate() + 1);
  return startDate;
}

export const MIN_WORK_HOURS = 8;

/**
 *  Receives a timeRange (Hours) and the day formatted
 * @param {{work_start: string, work_end: string}} timeRange - hours
 * @param {dateFormatted} dateFormatted - Date
 * @return {Object} formatted with the time range
 */
export function getFormattedDay(timeRange, dateFormatted) {
  if (!dateFormatted || !timeRange) {
    throw new Error('Invalid date');
  }

  const {work_start, work_end} = timeRange;
  return {
    start: moment(dateFormatted + ' ' + work_start).format(),
    end: moment(dateFormatted + ' ' + work_end).format(),
  };
}

export const DEFAULT_FORMAT_TIME = 'MMM DD, YYYY';

export const DATE_OPTIONS = {
  today: 'days',
  week: 'isoweek',
  month: 'months',
  year: 'years',
  date_range: DEFAULT_FORMAT_TIME,
};

export const DATE_OPTION_FORMAT = {
  today: DEFAULT_FORMAT_TIME,
  week: DEFAULT_FORMAT_TIME,
  month: 'MMMM YYYY',
  year: 'YYYY',
  date_range: DEFAULT_FORMAT_TIME,
};

export const MONTHS = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

/**
 *  Generates a startTime and endTime for the date range
 * @param {String} date - a date in the format YYYY-MM-DD or YYYY-MM-DD - YYYY-MM-DD
 * @param {String} period - a period of time
 * @return {{ startDate: String, endDate: String }} variables
 */
export const generateDateRangeVariables = (date, period) => {
  const defaultDate = moment().format('YYYY-MM-DD');
  const variables = {startDate: moment(defaultDate).startOf('day').toISOString(), endDate: moment(defaultDate).endOf('day').toISOString()};
  if (!date) return variables;

  if (period === 'date_range' || period === 'week') {
    const [start, end] = date.split(' - ');
    variables.startDate = moment(start.split('T')[0]).toISOString();
    variables.endDate = moment(end.split('T')[0]).toISOString();
  }

  if (period === 'month') {
    const currentDate = moment(date);
    variables.startDate = currentDate.startOf('month').toISOString();
    variables.endDate = currentDate.endOf('month').toISOString();
  }

  if (period === 'year') {
    const currentDate = moment(date);
    variables.startDate = currentDate.startOf('year').toISOString();
    variables.endDate = currentDate.endOf('year').toISOString();
  }

  if (period === 'today') {
    variables.startDate = moment(date).startOf('day').toISOString();
    variables.endDate = moment(date).endOf('day').toISOString();
  }
  
  return variables;
};

/**
 *  Receives a date and set it to the beginning of that day at local time
 * @param {Number} durationValue - duration from the API
 * @param {String} unit - time unit of the duration
 * @param {String} format - time format
 * @return {String} time formatted
 */
export const parseDurationToHumanReadable = (
  durationValue,
  unit = 'seconds',
  format = 'h[h] m[m] s[s]'
) => {
  const duration = moment.duration(durationValue, unit);
  const formattedDuration = duration.format(format);
  return formattedDuration;
};

const parseTimeUnit = (time) => {
  if (!time) {
    return '00';
  }

  if (time.length === 1) {
    return `0${time}`;
  }

  return time;
};

export const parseDurationDataTable = (durationValue) => {
  const duration = moment.duration(durationValue, 'seconds');
  const formattedDuration = duration.format('h:m:s');
  const splittedDuration = formattedDuration.split(':');

  if (splittedDuration.length === 1) {
    const seconds = splittedDuration[0];
    const formattedSeconds = parseTimeUnit(seconds);
    return `00:00:${formattedSeconds}`;
  }

  if (splittedDuration.length === 2) {
    const minutes = splittedDuration[0];
    const seconds = splittedDuration[1];
    const formattedMinutes = parseTimeUnit(minutes);
    const formattedSeconds = parseTimeUnit(seconds);
    return `00:${formattedMinutes}:${formattedSeconds}`;
  }

  const [hours, minutes, seconds] = splittedDuration;

  const formattedHours = parseTimeUnit(hours);
  const formattedMinutes = parseTimeUnit(minutes);
  const formattedSeconds = parseTimeUnit(seconds);

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
