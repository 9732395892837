import {castType} from '@/utils/index';

/**
 * Receives a setting subject
 * with nested sections and settings
 * and ignore settings that are used for displaying purposes
 * and map all the section settings after being cast to the correspondent type
 * @param {Object} data - the name of the table where the
 * setting value is stored
 * @return {Function} Function to map the settings
 */
export function mapSettings({setting_section: sections}) {
  for (const section of sections) {
    section.data = section.settings.reduce((result, setting)=>{
      const {type, value, setting_value} = setting;
      if (value.isNotField) return result;
      result[setting.id] = value.default;
      if (setting_value.length > 0) {
        result[setting.id] = castType(type, setting_value[0].value);
      }
      return result;
    }, {});
  }
  return sections;
}

/**
 * Function to convert the settings of a subject in a
 * hash object with the form: [setting_name]: [setting_value]
 * @param {Object} subject instance with an array of setting values
 * @return {Object} hash object
 */
export function mapSubjectSettings({setting_value}) {
  return setting_value.reduce((result, {value, setting})=>{
    result[setting.name] = castType(setting.type, value);
    return result;
  }, {});
}


export const SETTING_COL_LAYOUT = {cols: 12, md: 6, lg: 4};
