<template>
  <div>
    <v-snackbar
      v-model="enable"
      :timeout="timeout"
      color="navy_blue.lighten2"
      multi-line
    >
      <v-row
        align="center"
        justify="space-between"
      >
        <div style="width:64px; height:64px;">
          <v-img
            :src="require('@/assets/+tracker pet.svg')"
            width="64"
            height="64"
            contain
          />
        </div>

        <v-row
          align="center"
          justify="center"
          no-gutters
        >
          <v-col
            cols="12"
            class="pa-0"
          >
            <v-row
              align="center"
              no-gutters
              class="subtitle-2 white--text"
            >
              <v-icon
                color="white"
                class="mr-1"
              >
                mdi-information-outline
              </v-icon>
              New Version available!
            </v-row>

            <h5 class="pa-0 ml-1 ">
              Refresh to use the latest version.
            </h5>
          </v-col>
        </v-row>
        <v-btn
          color="accent_blue"
          class="pa-0 ml-2 text-capitalize"
          retain-focus-on-click
          :ripple="false"
          @click="refreshApp"
        >
          Refresh
        </v-btn>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class UpdatePwa extends Vue {
  timeout = -1;
  registration;
  refreshing = false;
  enable = false;

  mounted() {
    if ('serviceWorker' in navigator) {
      document.addEventListener('swUpdated', this.showRefreshUI, {once: true});
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  }

  showRefreshUI(e) {
    this.registration = e.detail;
    this.enable = true;
  }

  refreshApp() {
    this.enable = false;
    if (!this.registration) {
      return;
    }

    this.registration.waiting.postMessage('skipWaiting');
  }
}
</script>
