import Vue from 'vue';
import {Api} from '@/services/Api';

const ApiPlugin = {
  install(Vue) {
    Vue.prototype.$api = new Api();
  },
};

Vue.use(ApiPlugin);
