import {
  ACTIONS,
  RESOURCES,
  MEMBER_STATUS,
  PROJECT_MEMBER_STATUS,
} from '@/utils/enums';
import {MEMBER_ROLE} from '@/utils/enums';

const {
  WORKSPACE,
  MEMBER,
  WORKSPACE_INTEGRATION,
  PROJECT_INTEGRATION,
  PROJECT_MEMBER,
} = RESOURCES;

const {
  UPDATE,
  INVITE,
  DELETE,
  JOIN,
  ADD,
  MODIFY,
} = ACTIONS;
export default (user) => {
  return [
    {
      subject: MEMBER,
      action: [UPDATE, INVITE, DELETE],
      conditions: {
        role: MEMBER_ROLE.OWNER,
      },
      inverted: true,
    },
    {
      subject: MEMBER,
      action: [UPDATE, INVITE],
      conditions: {
        user_id: user.id,
      },
      inverted: true,
    },
    {
      subject: MEMBER,
      action: INVITE,
      conditions: {
        status: MEMBER_STATUS.ACTIVE,
      },
      inverted: true,
    },
    {
      subject: MEMBER,
      action: DELETE,
      conditions: {
        status: MEMBER_STATUS.UNINVITED,
      },
      inverted: true,
    },
    {
      subject: WORKSPACE,
      action: DELETE,
      conditions: {
        personal: true,
      },
      inverted: true,
    },
    {
      subject: WORKSPACE_INTEGRATION,
      action: ADD,
      conditions: {
        'integrations.length': 0,
      },
      reason: 'no_user_integration_available',
      inverted: true,
    },
    {
      subject: PROJECT_INTEGRATION,
      action: ADD,
      conditions: {
        'integrations.length': 0,
      },
      reason: 'no_user_integration_available',
      inverted: true,
    },
    {
      subject: PROJECT_INTEGRATION,
      action: ADD,
      conditions: {
        'workspaceIntegrations.length': 0,
      },
      reason: 'no_workspace_integration_available',
      inverted: true,
    },
    {
      subject: PROJECT_MEMBER,
      action: MODIFY,
      conditions: {
        'user.id': user.id,
      },
      inverted: true,
    },
    {
      subject: PROJECT_MEMBER,
      action: JOIN,
      conditions: {
        status: PROJECT_MEMBER_STATUS.ALLOWED,
      },
      inverted: true,
    },
    {
      subject: PROJECT_MEMBER,
      action: DELETE,
      conditions: {
        status: PROJECT_MEMBER_STATUS.UNALLOWED,
      },
      inverted: true,
    },
    {
      subject: PROJECT_MEMBER,
      action: MODIFY,
      conditions: {
        role: {$in: [MEMBER_ROLE.OWNER, MEMBER_ROLE.ADMIN]},
      },
      inverted: true,
    },
    {
      subject: PROJECT_MEMBER,
      action: MODIFY,
      conditions: {
        'project.public': true,
      },
      inverted: true,
    },
  ];
};
