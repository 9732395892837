import {usePaginatedSearch} from '@/composables/pagination';
import moment from 'moment';

export const buildSearchFilters = () => {
  return {
    client: usePaginatedSearch(),
    project: usePaginatedSearch(),
    member: usePaginatedSearch(),
  };
};

export const SERIE_COLOR = {
  project_name: 'project_color',
  task_name: undefined,
  user_email: undefined,
};

export const DEFAULT_SERIE_NAME = {
  'No Project': 'no_project',
  'No Task': 'no_task',
  'No Client': 'no_client',
};

export const MIN_NUMBER_DATE = 7;


export const MAX_MONTHS_LIMIT = 12;


export const GROUP_BY_FILTER_DATE = {
  today: 'date',
  week: 'date',
  month: 'date',
  year: 'month_name',
  date_range: 'year_actual',
};

export const DATE_STATUS = {
  today: 'today',
  week: 'week',
  month: 'month',
  year: 'year',
  date_range: 'date_range',
};

export const GROUP_BY_MAP = {
  project: {
    idKey: 'project_name',
    group: 'project',
    nameKey: 'project_name',
    colorKey: 'project_color',
    groupByFields: ['project_name', 'project_color', 'project_id', 'client_name'],
  },
  member: {
    idKey: 'user_name',
    group: 'member',
    nameKey: 'user_name',
    colorKey: undefined,
    groupByFields: ['user_name', 'user_id'],
  },
  client: {
    idKey: 'client_name',
    group: 'client',
    nameKey: 'client_name',
    colorKey: undefined,
    groupByFields: ['client_name', 'client_id'],
  },
};

export const formattedDateQuery = (date, period) => {
  if (!date) {
    return {};
  }

  let formattedDate = {};
  const [lowerLimit, , upperLimit] = date.split(' ');

  switch (period) {
    case DATE_STATUS.today:
      formattedDate = {
        start: moment.utc(date).startOf('day').format(),
        end: moment.utc(date).endOf('day').format(),
        groupBy: GROUP_BY_FILTER_DATE[period],
      };
      break;
    case DATE_STATUS.week:
      const fixEndLimit = moment(upperLimit).startOf('day');

      formattedDate = {
        start: moment.utc(lowerLimit).startOf('day').format(),
        end: moment.utc(fixEndLimit).format(),
        groupBy: GROUP_BY_FILTER_DATE[period],
      };
      break;
    case DATE_STATUS.month:
      formattedDate = {
        start: moment.utc(date).startOf('month').format(),
        end: moment.utc(date).endOf('month').format(),
        groupBy: GROUP_BY_FILTER_DATE[period],
      };
      break;
    case DATE_STATUS.year:
      const start = moment.utc(date).startOf('year').format();
      const end = moment.utc(date).endOf('year').format();

      formattedDate = {
        start: start,
        end: end,
        groupBy: GROUP_BY_FILTER_DATE[period],
      };
      break;
    default:
      const isMonths = getMonthDifference(date);
      if (isMonths < 1) {
        formattedDate = {
          start: moment.utc(lowerLimit).format(),
          end: moment.utc(upperLimit).format(),
          groupBy: GROUP_BY_FILTER_DATE[DATE_STATUS.week],
        };
      } else if (isMonths >= 1 && isMonths <= MAX_MONTHS_LIMIT) {
        formattedDate = {
          start: moment.utc(lowerLimit).format(),
          end: moment.utc(upperLimit).format(),
          groupBy: GROUP_BY_FILTER_DATE[DATE_STATUS.year],
        };
      } else {
        formattedDate = {
          start: moment.utc(lowerLimit).format(),
          end: moment.utc(upperLimit).format(),
          groupBy: GROUP_BY_FILTER_DATE[period],
        };
      }
      break;
  }
  return formattedDate;
};

export const getMonthDifference = (date) => {
  const [lowerLimit, , upperLimit] = date.split(' ');
  return moment(upperLimit).diff(lowerLimit, 'months', true);
};

export const legendWidthMap = {
  name: {
    xs: 150,
    sm: 200,
    lg: 150,
    md: 150,
    xl: 250,
    default: 200,
  },
};
export const legendRightPaddingMap = {
  xs: null,
  sm: '0%',
  lg: '5%',
  md: '0%',
  default: '15%',
};
export const legendTruncateMap = {
  xs: 20,
  sm: 30,
  lg: 20,
  md: 20,
  xl: 20,
  default: 30,
};

export const INDICATOR_COLORS = {
  red: 'red',
  green: 'green',
};

export const buildVariables = (variables) => {
  const {workspace_id, start, end, groupBy, filters, timeZone} = variables;
  const {member, project, client} = filters;
  const defaultFilters = {
    project_id: {},
    user_id: {},
    client_id: {},
  };
  let queryVariables = {workspace_id, start, end, groupBy, filter: defaultFilters, timeZone};

  if (project && project.selected.length !== 0) {
    queryVariables = Object.assign({}, queryVariables, {
      filter: Object.assign({}, queryVariables.filter, {project_id: {in: [...project.selected]}}),
    });
  }

  if (member && member.selected.length !== 0) {
    queryVariables = Object.assign({}, queryVariables, {
      filter: Object.assign({}, queryVariables.filter, {user_id: {in: [...member.selected]}}),
    });
  }

  if (client && client.selected.length !== 0) {
    queryVariables = Object.assign({}, queryVariables, {
      filter: Object.assign({}, queryVariables.filter, {client_id: {in: [...client.selected]}}),
    });
  }

  return queryVariables;
};

// eslint-disable-next-line require-jsdoc
export function renderTimeTracked(data) {
  return `
      <div class="v-card elevation-5" style="height: auto">
        <div
          class="v-card d-flex justify-space-between"
          style="background-color:${data.color};color: white;width:auto">
              <span class="ml-2">
                <b>${data.name} </b>
              </span>
              <span class="mr-1 ml-1"> ${data.percentage}</span>
        </div>
         <span class="ml-2 text-capitalize"> ${data.titleHours} </span>
         <br>
          <b class="ml-2 mb-2"> ${data.time}</b>
      </div>`;
}
