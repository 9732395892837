/**
 * This is a function will allow you to get the user initials.
 *
 * @param {string} displayName - The user display name
 * @return {string} - The user's initials (Two letters)
 */
export function getInitials(displayName) {
  const name = displayName ? displayName.split(' ') : ['', ''];
  let initial = name[0][0] + (name[1] ? name[1][0] : name[0][1] || name[0][0]);
  if (name.length > 2) {
    initial = name[0][0] + name[2][0];
  }
  return initial ? initial.toUpperCase() : '';
}
