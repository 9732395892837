import {ACTIONS, FIELDS, RESOURCES} from '@/utils/enums';

const {
  PAGES,
  WORKSPACE,
  MEMBER,
  PROJECT,
  PROJECT_INTEGRATION,
  PROJECT_MEMBER,
  TASK,
  DASHBOARD,
} = RESOURCES;

const {
  UPDATE,
  DELETE,
  VIEW,
  INVITE,
  CREATE,
  ADD,
  MODIFY,
  FILTER_MEMBER,
} = ACTIONS;


export default ()=> {
  return [
    // Workspaces
    {
      subject: WORKSPACE,
      action: [UPDATE],
    },
    {
      subject: PAGES.WORKSPACE.SETTINGS.BASIC_SETTINGS,
      action: [VIEW],
    },
    {
      subject: PAGES.WORKSPACE.SETTINGS.INTEGRATIONS,
      action: [VIEW],
    },
    {
      subject: PAGES.WORKSPACE.SETTINGS.MEMBERS,
      action: [VIEW],
    },
    {
      subject: PAGES.WORKSPACE.SETTINGS.BILLING,
      action: [VIEW],
    },
    {
      subject: PAGES.WORKSPACE.SETTINGS.TIME_SETTINGS,
      action: [VIEW],
    },
    {
      subject: PAGES.WORKSPACE.SETTINGS.FEATURES,
      action: [VIEW],
    },
    // Members
    {
      subject: MEMBER,
      action: [UPDATE],
      fields: [FIELDS.MEMBER.ROLE],
    },
    {
      subject: MEMBER,
      action: [INVITE, DELETE],
    },
    // Projects
    {
      subject: PAGES.PROJECT.CREATE,
      action: [VIEW],
    },
    {
      subject: PROJECT,
      action: [CREATE, UPDATE, DELETE],
    },
    {
      subject: PROJECT_INTEGRATION,
      action: [ADD, DELETE],
    },
    {
      subject: PAGES.PROJECT.SETTINGS.BASIC_SETTINGS,
      action: [VIEW],
    },
    {
      subject: PAGES.PROJECT.SETTINGS.BASIC_SETTINGS,
      action: [VIEW],
    },
    {
      subject: PAGES.PROJECT.SETTINGS.PERMISSIONS,
      action: [VIEW],
    },
    // Project members
    {
      subject: PROJECT_MEMBER,
      action: MODIFY,
    },
    // Tasks
    {
      subject: TASK,
      action: FILTER_MEMBER,
    },
    {
      subject: DASHBOARD,
      action: FILTER_MEMBER,
    },
    {
      subject: TASK,
      action: UPDATE,
    },
  ];
};
