/**
 * Middleware to send a message to opener window
 * after a successful oauth integration
 * GraphQL API
 *  * @param {object} object with next url and the store
 **/
export async function addIntegration({to, next}) {
  const {integration, token, refresh, email, state} = to.query;
  if (!!integration && !!token && !!email && !!state) {
    const {opener, location} = window;
    opener.postMessage({integration, token, refresh, email, state}, location.origin);
    window.close();
  } else {
    next('/');
  }
}
