import apolloClient from '@/apollo/client';
import {getProjectDetails} from '@/graphql/project.gql';

export const projectExists = async ({next, to}) => {
  const project = await apolloClient.query({
    query: getProjectDetails,
    variables: {
      id: to.params.projectId,
    },
  }).then(({data})=>data.project);
  if (!project) {
    return next('/not-found');
  }
  return next();
};
