import VImageInput from 'vuetify-image-input/a-la-carte';

export default {
  extends: VImageInput,
  mixins: [
    {
      props: {
        uploadIcon: {default: 'mdi-camera'},
      },
    },
  ],
};
