<!--eslint-disable max-len-->
<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="24"
      cy="24"
      r="24"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.4829 47.7491C39.0872 46.0622 48 36.0719 48 24C48 10.7452 37.2549 0 24 0C10.7451 0 0 10.7452 0 24C0 35.9408 8.72021 45.8448 20.1399 47.6912V30.749H14V23.64H20.1401V18.3971C20.1401 12.3129 23.8567 9 29.2854 9C31.8857 9 34.1206 9.19348 34.7722 9.27985V15.6381L31.0068 15.6398C28.0544 15.6398 27.4829 17.0426 27.4829 19.1008V23.6398H34.5239L33.6072 30.7488H27.4829V47.7491Z"
      fill="#1877F2"
    />
  </svg>
</template>
<script>
export default {};
</script>
