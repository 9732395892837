/**
* Middleware to verify the user is authenticated
* otherwise it will be redirected to the login route.
* @param {object} object with next url and the store
* @return {object} object with next url and the store
**/
export function auth({next, store}) {
  const isAuthorized = store.getters['auth/isAuthorized'];
  if (!isAuthorized) {
    return next({
      name: 'sign-in',
    });
  }

  return next();
}
/**
 * Middleware to verify the user is not authenticated
 * otherwise it will be redirected to the dashboard route.
 * @param {object} object with next url and the store
 * @return {object} object with next url and the store
 **/
export function guest({next, store}) {
  const isAuthorized = store.getters['auth/isAuthorized'];
  if (isAuthorized) {
    return next({
      name: 'dashboard',
    });
  }

  return next();
}
/**
 * Middleware to verify the user is not authenticated
 * otherwise it will be redirected to the dashboard route.
 * @param {object} object with next url and the store
 * @return {object} object with next url and the store
 **/
export function unverifiedEmailUser({next, store}) {
  const state = store.state.auth;
  if (!state.user.is_authenticated || state.user.is_verified) {
    return next('/');
  }

  return next();
}
