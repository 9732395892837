import {split} from 'apollo-link';
import {getMainDefinition} from 'apollo-utilities';
import httpLink from './http';
import websocketLink from './websocket';


const COMBINED_LINK = split(
    ({query}) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
      );
    },
    websocketLink,
    httpLink,
);

export default COMBINED_LINK;
