const getDefaultState = () => ({
  count: 0,
  snackbars: [],
});
export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    PUSH(state, snackbar) {
      state.count++;
      state.snackbars.push({id: state.count, ...snackbar});
    },
    REMOVE(state, snackbar) {
      const index = state.snackbars.indexOf(snackbar);
      state.snackbars.splice(index, 1);
    },
    RESET(state) {
      state = getDefaultState();
    },
  },
  actions: {},
  getters: {},
  modules: {},
  getDefaultState,
};
