import apolloClient from '@/apollo/client';
import {getIntegrationById} from '@/graphql/integration_app.gql';
import {
  createUserIntegration,
  deleteUserIntegration,
  getUserIntegrations,
} from '@/graphql/integration_user.gql';
import TranslatableError from '@/utils/errors/TranslatableError';
import i18n from '@/plugins/i18n';

const getDefaultState = () =>({});

const actions = {
  async addIntegration({rootState}, data) {
    const {integration, token, email, refresh, state: stateNonce} = data;
    const integrationId = parseInt(integration);
    const {user} = rootState.auth;
    const params = {
      query: getIntegrationById,
      variables: {id: integrationId},
    };
    const {data: response} = await apolloClient.query(params);
    const {integration_app} = response;
    if (integration_app.length === 0) {
      throw new TranslatableError('not_found', [i18n.tc('integration', 1)]);
    }
    if (integration_app[0].nonce !== stateNonce) {
      throw new TranslatableError('invalid_integration_origin');
    }
    await apolloClient.mutate({
      mutation: createUserIntegration,
      variables: {
        integration_id: integration,
        token,
        email,
        offline_token: refresh,
      },
      refetchQueries: [
        {query: getUserIntegrations, variables: {id: user.id}},
      ],
    });
  },
  async removeIntegration({rootState}, data) {
    const {id} = data;
    const {user} = rootState.auth;
    await apolloClient.mutate({
      mutation: deleteUserIntegration,
      variables: {id},
      refetchQueries: [
        {query: getUserIntegrations, variables: {id: user.id}},
      ],
    });
  },
};

export default {
  namespaced: true,
  mutations: {},
  actions,
  getDefaultState,
  state: getDefaultState(),
};
