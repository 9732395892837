/**
 * Middleware to handle get requests from firebase after a
 * user confirm an email action.
 * @param {object} context - object with next url and the store
 * @return {object} object with next url and the store
 **/
export function emailActionHandler(context) {
  const {to, store, next} = context;
  store.dispatch('auth/emailHandler/handleEmailAction', to.query);
  return next();
}
