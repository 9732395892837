/**
 *
 * @param {string} color - Hexadecimal color to lighten/darken
 * @param {number} percent - Percentage to lighten the color
 * @return {string} - Lighten color
 */
export function lightenColor(color, percent) {
  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const B = (num >> 8 & 0x00FF) + amt;
  const G = (num & 0x0000FF) + amt;
  return '#' + (0x1000000 +
    (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 +
    (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 +
    (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
}

/**
 * Theme palette generator, receives a hexadecimal color and
 * a total of tonnes to generate and returns a ParsedThemeItem
 * @param {string} color - Base color of the palette
 * @param {number} totalTones - Number of tones to generate
 * @return {object} palette - Palette following the interface ParsedThemeItem
 * defined by vuetify
 */
export function generatePalette(color, totalTones = 5) {
  const palette = {
    base: color,
  };
  for (let index = 1; index <= totalTones; index++) {
    palette[`lighten${index}`] = lightenColor(color, index * 5);
    palette[`darken${index}`] = lightenColor(color, - index * 5);
  }
  return palette;
}
