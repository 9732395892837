import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from '@/store/auth';
import userIntegration from '@/store/user-integration';
import form from '@/store/form';
import snackbar from '@/store/snackbar';
import app from '@/store/app';
import workspace from '@/store/workspace';
import timeline from '@/store/timeline';
import filter from '@/store/filter';
import dashboard from '@/store/dashboard';
import reports from '@/store/reports';

const modules = {
  auth,
  userIntegration,
  form,
  snackbar,
  app,
  workspace,
  timeline,
  filter,
  dashboard,
  reports,
};

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {},
  mutations: {
    RESET(state) {
      for (const [moduleName, module] of Object.entries(modules)) {
        state[moduleName] = module.getDefaultState();
      }
    },
  },
  actions: {},
  modules,
  plugins: [
    createPersistedState({
      paths: [
        'auth.user',
        'workspace.activeWorkspaceId',
        'app.isExpanded',
        'timeline',
        'filter.isMeMode',
      ],
    }),
  ],
});

export default store;
