import store from '@/store';
import apollo from '@/apollo/client';
import {WebSocketLink} from 'apollo-link-ws';
import {SubscriptionClient} from 'subscriptions-transport-ws';
import {getHeaders} from '@/utils/graphql';
import {from} from 'apollo-link';
import {
  WS_MIDDLEWARE_WRAPPER,
} from '@/apollo/client/links/websocket/middleware';

const WS_ENDPOINT = process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:8080/v1/graphql';

const subscriptionClient = new SubscriptionClient(WS_ENDPOINT, {
  reconnect: true,
  lazy: true,
  async connectionParams() {
    await store.dispatch('auth/refreshToken');
    return {
      headers: getHeaders(),
    };
  },
});

subscriptionClient.onConnected(async ()=>{
  await apollo.resetStore();
});

/**
 * This function allows to close the current websocket connection
 *
 */
export function closeWebsocket() {
  subscriptionClient.close(true, true);
}

const wsLink = new WebSocketLink(subscriptionClient);

const FINAL_WS_LINK = from([WS_MIDDLEWARE_WRAPPER, wsLink]);

export default FINAL_WS_LINK;
