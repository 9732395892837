import Vue from 'vue';
import VueApollo from 'vue-apollo';
import apolloClient from '../apollo/client';

const ApolloClientPlugin = {
  install: function(Vue) {
    Vue.prototype.$apolloClient = apolloClient;
  },
};

Vue.use(VueApollo);
Vue.use(ApolloClientPlugin);

export default new VueApollo({
  defaultClient: apolloClient,
});
