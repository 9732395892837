/**
 * Middleware to handle get requests from generated workspace invitations
 * @param {object} context - object with next url and the store
 * @return {object} object with next url and the store
 **/
export function workspaceInvitationHandler({to, next, store}) {
  const {
    require_change_password,
    workspace_name,
    workspace_photo,
    workspace_color,
    email_link,
  } = to.query;
  const isAuthorized = store.getters['auth/isAuthorized'];
  if (isAuthorized) return next('home');
  if (require_change_password === 'true') {
    store.commit('workspace/SET_INVITATION', {
      workspaceName: workspace_name,
      workspacePhoto: workspace_photo,
      workspaceColor: workspace_color,
    });
    store.commit('auth/emailHandler/SET_SIGN_IN_LINK', email_link);
    return next('workspace-invitation');
  }
  store.commit('form/SET_SUCCESS', {
    form: 'SignIn',
    message: 'success.member_invited_to_workspace',
    params: {
      workspaceName: workspace_name,
    },
  }, {root: true});
  return next('sign-in');
}
