/* eslint-disable max-len*/
/** NOTE: Each one of the translation modules MUST have a default value,
 * Otherwise if for any reason a key is not found in the fallback lang (english)
 * an infinite loop will be triggered in the function `missing`
 * in src/plugins/i18n.js
 */
const translations = {
  '$vuetify': {
    'close': 'close',
    'timePicker': {
      'am': 'AM',
      'pm': 'PM',
    },
    'datePicker': {
      'prevMonthAriaLabel': 'Previous',
      'nextMonthAriaLabel': 'Next',
      'itemsSelected': 'Selected',
      'prevYearAriaLabel': 'Previous',
      'nextYearAriaLabel': 'Next',
    },
    'badge': 'badge',
    'dataIterator': {
      'noResultsText': 'No results available',
      'loadingText': 'Loading...',
    },
    'noDataText': 'No data available',
    'dataTable': {
      'itemsPerPageText': 'Rows per page:',
      'ariaLabel': {
        'sortDescending': 'Sorted descending.',
        'sortAscending': 'Sorted ascending.',
        'sortNone': 'Not sorted.',
        'activateNone': 'Activate to remove sorting.',
        'activateDescending': 'Activate to sort descending.',
        'activateAscending': 'Activate to sort ascending.',
      },
      'sortBy': 'Sort by',
    },
    'dataFooter': {
      'itemsPerPageText': 'Items per page:',
      'itemsPerPageAll': 'All',
      'nextPage': 'Next page',
      'prevPage': 'Previous page',
      'firstPage': 'First page',
      'lastPage': 'Last page',
      'pageText': '{0}-{1} of {2}',
    },
    'pagination': {
      'ariaLabel': {
        'previous': 'Previous',
        'next': 'Next',
        'wrapper': 'Wrapper',
        'currentPage': 'Current Page',
        'page': 'Page',
      },
    },
  },
  'error': {
    'data_exception': 'oops, there was a problem, try again later',
    'default': 'oops, there was a problem, try again later',
    'wrong_password': 'the password is invalid or the user does not have a password',
    'user_not_found': 'there is no user record corresponding to this email',
    'weak_password': 'password should be at least 6 characters',
    'email_already_in_use': 'the email address is already in use by another account',
    'invalid_action_code': 'the action code is invalid, this can happen if the code is malformed, expired, or has already been used.',
    'too_many_requests': 'too many request, please wait a moment and retry',
    'integration_email_already_exists': 'you already have an integration registered with this email',
    'integration_token_already_exists': 'you already have an integration registered with this token',
    'only_one_app_per_workspace': 'only one workspace integration per app is allowed',
    'existing_workspaces_use_user_integration': 'this user integration is being used by a workspace',
    'error_removing_subject': 'error, unable to remove {0}',
    'missing_personal_integration': 'you need to setup a {0} personal integration, please go to user settings',
    'loading_workspace_integrations': 'there was a problem loading the integration, please contact your workspace owner and validate your personal integration',
    'expired_action_code': 'the action code has expired',
    'invalid_email': 'the email address is badly formatted',
    'only_one_app_per_project': 'only one project integration per app is allowed',
    'overlapping_task_intervals_not_allowed': 'the task is already associated in this time interval',
  },
  'success': {
    'verification_email_sent': 'verification email sent successfully, please check your inbox',
    'reset_password_email_sent': 'reset password email sent successfully, please check your inbox',
    'change_password_successful': 'password changed successfully',
    'update_profile': 'your information was updated successfully',
    'save_settings': 'settings successfully saved',
    'workspace_left': 'workspace left successfully',
    'workspace_integrated': 'workspace integrated successfully',
    'workspace_status_changed': 'workspace integration status updated successfully',
    'workspace_invitation_sent': 'workspace invitation sent successfully',
    'member_deleted': 'workspace member deleted successfully',
    'role_updated': 'workspace member role updated successfully',
    'invitation_cancelled': 'invitation cancelled successfully',
    'project_integrated': 'project integrated successfully',
    'member_joined_project': 'member joined the project successfully',
    'member_removed_from_project': 'member removed from project successfully',
    'member_invited_to_workspace': 'you have been invited to "{workspaceName}", sign in to continue',
  },

  // General
  'add': 'add',
  'cancel': 'cancel',
  'save': 'save',
  'yes': 'yes',
  'no': 'no',
  'me': 'me',
  'delete': 'delete',
  'removing': 'Removing',
  'search': 'search',
  'refresh': 'refresh',
  'pagination': 'page {0} of {1}',
  'items_per_page': 'items per page',

  // labels
  'full_name': 'full name',
  'name': 'name',
  'email': 'email',
  'username': 'username',
  'password': 'password',
  'new_password': 'new password',
  'confirm_password': 'confirm password',
  'stay_logged_in': 'stay logged in',
  'accept_policy': 'accept policy terms',
  'color': 'color',
  'logo': 'logo',
  'workspace_name': '@:workspace\'s name',
  'time_zone': 'time zone',
  'time_format': 'time format',
  'work_start': 'work start',
  'work_end': 'work end',
  'minimum_hours': 'minimum hours',
  'tracker_start': 'tracker start',
  'tracker_end': 'tracker end',
  'industry': 'industry',
  'country': 'country',
  'currency': 'currency',
  'rate': 'rate',
  'enable': 'enable',
  'disable': 'disable',
  'approve_only': 'approve only',
  'offline_time': 'offline time',
  'screenshot_interval': 'screenshot interval',
  'screenshot_quality': 'screenshot quality',
  'allow_screenshot': 'allow screenshot',
  'blur_screenshot': 'blur screenshot',
  'see_dashboards_reports': 'see dashboards/reports',
  'disable_url_tracking': 'disable url tracking',
  'disable_windows_title': 'disable windows title',
  'disable_private_time': 'disable private time',
  'disable_calendar': 'disable calendar',
  'allow_project_cost': 'allow project cost',
  'hide_owner': 'hide owner',
  'hide_schedule': 'hide schedule',
  'hide_colleagues': 'hide colleagues',
  'admins': 'admins',
  'everyone': 'everyone',
  'tracking_days': 'tracking days',
  'working_days': 'working days',
  'mo': 'mo',
  'tu': 'tu',
  'we': 'we',
  'th': 'th',
  'fr': 'fr',
  'sa': 'sa',
  'su': 'su',
  'public': 'public',
  'private': 'private',
  'shared': 'shared',
  'start_time': 'start time',
  'stop_time': 'stop time',
  'date': 'date',

  // Error labels
  'passwords_dont_match': 'password don\'t match',

  // links
  'forgot_password': 'forgot password?',
  'dont_have_account': 'don\'t have an account?',
  'already_have_account': 'i already have an account',

  // Button
  'send_email': 'send email',
  'sign_in': 'sign in',
  'sign_up': 'sign up',
  'continue_with': 'continue with',
  'create': 'create',
  'edit': 'edit',
  'set_new_password': 'set new password',
  'add_new_btn': 'add new',
  'invite': 'invite',
  'resend_invite': 'resend invite',
  'cancel_invite': 'cancel invite',
  'role': 'role',
  'status': 'status',
  'actions': 'actions',

  // Rules
  'is_required': 'required',
  'agree_to_continue': 'you must agree to continue',
  'password_dont_match': 'passwords don\'t match',

  // Titles
  'verify_email': 'please verify your email',
  'verify_email_description': 'once you verify your email address,' +
    '\nyou and your team can get started with +tracker!',
  'didnt_receive_email': 'didn\'t received an email?',
  'resend_email': 'resend',
  'title': 'title',

  // Drawer
  'time_entries': 'time entries',
  'tasks': 'tasks',
  'dashboards': 'dashboards',
  'reports': 'reports',
  'settings': 'settings',
  'logout': 'logout',

  // Settings
  'basic_settings': 'basic settings',
  'display': 'display',
  'profile_photo': 'profile photo',
  'existing_integrations': 'existing integrations',
  'upload_photo': 'upload your photo',
  'upload_logo': 'upload your logo',
  'remove_photo': 'remove photo',
  'workspace_settings': 'workspace settings',
  'workspace_logo': 'logo',
  'time_tracking': 'time tracking',
  'track_time_in_personal_workspace': 'time tracking in personal workspace',

  // Subject messages
  'create_subject': 'create {0}',
  'edit_subject': 'edit {0}',
  'add_subject': 'add {0}',
  'subject_added': '{subject} added successfully',
  'subject_updated': '{subject} updated successfully',
  'subject_removed': '{subject} removed successfully',
  'subject_remove_confirm': 'do you wanna delete this {subject}?',
  'select_subject': 'select {0}',
  'field_updated': '{field} updated successfully',

  // subjects
  'integration': 'integration | integrations',
  'workspace': 'workspace | workspaces',
  'task': 'task | tasks',
  'project': 'project | projects',
  'client': 'client | clients',
  'member': 'member | members',
  'action': 'action | actions',
  'workspace_integration': 'workspace integration',
  'project_integration': 'project integration',
  'time_interval': 'time interval | time intervals',
  'interval': 'interval | intervals',

  // Verify email success
  'email_successfully_verified': 'your email has been successfully verified',
  'start_using_tracker': 'now you can start using +tracker!',
  'go_to_dashboard': 'go to dashboard',

  // languages
  'language': 'language',
  'spanish': 'spanish',
  'english': 'english',

  // Workspace settings tabs
  'time_settings': 'time @:settings',
  'billing': 'billing',
  'features': 'features',
  'integrations': 'integrations',
  'members': 'members',
  'permissions': 'permissions',
  'owner': 'owner',
  'leave_workspace': 'leave workspace',

  // Confirm messages
  'confirm_leave_workspace': 'are you sure you want to leave this workspace?',
  'confirm_delete_member': 'are you sure you want to delete this member? | are you sure you want to delete these members?',
  'confirm_cancel_invite': 'are you sure you want to cancel this invitation?',
  'confirm_delete_member_project': 'are you sure you want to remove these users from the project?',

  // Integrations
  'no_user_integration_available': 'you dont have any personal integrations, please go to user settings to add your personal integration',
  'no_workspace_integration_available': 'this workspace doesn\'t have any integration associated, please add one in the workspace integrations tab (Only the owner can do this)',
  'only_owners_can_add_integrations': 'only the owner can add integrations',
  'workspace_doesnt_have_integrations': 'this workspace doens\'t have any integration associated, please add one in the integrations tab',

  'my_account': 'my account',
  'dark_mode': 'dark mode',

  // Member status
  'uninvited': 'uninvited',
  'invited': 'invited',
  'active': 'active',

  // Member roles
  'admin': 'admin | admins',

  // Projects
  'allow_project_access': 'allow access to the project',
  'unallow_project_access': 'remove access to the project',
  'allowed': 'allowed',
  'unallowed': 'unallowed',
  'selected': 'selected',

  // Tasks
  'due_date': 'due date',
  'time_estimate': 'time estimate',
  'time_spent': 'time spent',
  'remaining': 'remaining',
  'manage_integrations': 'manage integrations',
  'invalid_human_time': 'invalid time format',
  'time_format_placeholder': 'example: 3h 30m',
  'assignees': 'assignees',
  'none': 'None',
  'show_completed': 'show completed',
  'can_member_estimate': 'members can estimate tasks',
  'can_member_complete': 'members can complete tasks',

  // Time Entries
  'no_data_today': 'There is no data available for this day.',
  'productivity': 'performance',
  'activity_pulse': 'Activity Pulse',
  'highlight_summary': 'Highlight Summary',
  'inactive': 'inactive',
  'pending': 'pending',
  'recommended': 'Recommended',
  'sync_intervals': 'Sync. Intervals',
  'monitor_tracked': 'Monitor Tracked',
  'button_interval': 'Add Interval',
  'activity': 'activity',
  'task_time': 'Task Time',
  'time': 'time',
  'completed': 'completed',
  'based_on_your_behavior': 'Based On Your Behavior',
  'timeline': 'timeline',
  'new_task': 'new task',
  'extra': 'extra',
  'tracked': 'tracked',
  'go_to': 'go to',
  'sync_time_intervals': 'sync time intervals',
  'time_entry_detail_title': 'time entry detail',
  'delete_screenshot': 'Delete Screenshot',
  'screenshot': 'interval time',
  'activity_average': 'activity average',
  'related_task': 'related task',
  // workspace invitation
  'workspace_invitation_new_user': 'you are invited to the {workspaceName} workspace, please set your password to continue!',
  'no_result_found': 'no results found',
  // filtros
  'filters': 'filters',
  'others': 'others',
  'no_data_available': 'no data available',
  'apply_filter': 'apply filter',
  'select_all': 'select all',
  // date menu
  'today': 'today',
  'week': 'this week',
  'month': 'this month',
  'year': 'this year',
  'date_range': 'date range',

  // dashboards
  'time_tracked': 'time tracked',
  'jan': 'Jan',
  'feb': 'Feb',
  'mar': 'Mar',
  'apr': 'Apr',
  'may': 'May',
  'jun': 'Jun',
  'jul': 'Jul',
  'aug': 'Aug',
  'sep': 'Sep',
  'oct': 'Oct',
  'nov': 'Nov',
  'dec': 'Dec',
  'mon': 'Mon',
  'tue': 'Tue',
  'wed': 'Wed',
  'thu': 'Thu',
  'fri': 'Fri',
  'sat': 'Sat',
  'sun': 'Sun',
  'no_project': 'Without project',
  'no_task': 'Without task',
  'no_client': 'Without client',
  'mode_calendar': 'calendar',
  'group_by': 'group by',
  'summary': 'summary',
  'no_dashboard': 'You don\'t have activity to graph in this query!',
  'total_hours_tracked': 'total hours tracked',
  'top_project': 'top project',
  'top_task_tracked': 'top task tracked',
  'comparison_today': 'compare to the last day',
  'comparison_week': 'compare to the last week',
  'comparison_month': 'compare to the last month',
  'comparison_year': 'compare to the last year',
  'comparison_range': 'compare to the last range',
  'total_hours': 'total hours',
  'upload': 'upload',
  'me_mode': 'me mode',
};
export const grammaticalGender = {};
export default translations;
