/**
Function in charge to execute all the middlewares in order.
@param {object} context - Context object
@param {object} middleware - Middleware
@param {number} index - Index
@return {function} Function with next middleware to be executed
**/
function middlewarePipeline(context, middleware, index) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextPipeline = middlewarePipeline(
        context, middleware, index + 1
    );

    nextMiddleware({...context, next: nextPipeline});
  };
}

export default middlewarePipeline;
