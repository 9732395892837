import {MEMBER_ROLE} from '@/utils/enums';

/**
 * Load all permissions defined for the logged in user
 * @param {Object} user - Logged user
 * @param {String} role - Role in the workspace
 * @return {*[]} - Return a promise with an array of rules
 * for the ability builder
 */
export function loadWorkspacePermissions(user, role) {
  const loaders = require.context('./rules', false, /\.js$/);
  const ROLE_RULES = {
    [MEMBER_ROLE.OWNER]: ['owner-admin', 'owner'],
    [MEMBER_ROLE.ADMIN]: ['member-admin', 'owner-admin', 'admin'],
    [MEMBER_ROLE.MEMBER]: ['member-admin', 'member'],
  };
  const loadersKeys = [...ROLE_RULES[role], 'base'];
  const rules = [];
  for (const key of loadersKeys) {
    const loader = loaders(`./${key}.js`).default;
    rules.push(...loader(user, role));
  }
  return rules;
}


