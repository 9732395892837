export const CONSTRAINTS = {
  'default': {
    code: 'unknown_constraint_violated',
  },
  'integration_user_token_key': {
    code: 'integration_token_already_exists',
  },
  'integration_user_integration_app_id_email_key': {
    code: 'integration_email_already_exists',
  },
  'workspace_integration_app_uk': {
    code: 'only_one_app_per_workspace',
  },
  'workspace_integration_app_integration_user_id_fkey': {
    code: 'existing_workspaces_use_user_integration',
  },
  'project_integration_app_integration_app_id_project_id_key': {
    code: 'only_one_app_per_project',
  },
  'overlapping_interval': {
    code: 'overlapping_task_intervals_not_allowed',
  },
  'configuration_workspace_uk': {
    code: 'workspace_setting_value_already_exists',
  },
};
