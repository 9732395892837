import {VTextField} from 'vuetify/lib';

export default {
  extends: VTextField,
  mixins: [
    {
      props: {
        outlined: {default: true},
      },
    },
  ],
};
