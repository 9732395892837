import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

export default {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

export const AuthProviders = {
  'google': {
    icon: '$google',
    constructor: firebase.auth.GoogleAuthProvider,
  },
  'github': {
    icon: '$github',
    constructor: firebase.auth.GithubAuthProvider,
  },
  'facebook': {
    icon: '$facebook',
    constructor: firebase.auth.FacebookAuthProvider,
  },
};
