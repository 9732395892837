import {computed, watch, ref} from '@vue/composition-api';
import debounce from 'lodash/debounce';
import {ITEM_PER_PAGE_DEFAULT} from '@/utils/enums';

export const usePagination = ()=>{
  const page = ref(1);
  const itemsPerPage = ref(ITEM_PER_PAGE_DEFAULT);
  const totalItems = ref(null);
  const numberOfPages = computed(()=>{
    if (!totalItems.value || !itemsPerPage.value) return 0;
    return Math.ceil(totalItems.value / itemsPerPage.value);
  });
  const offsetPosition = computed(()=>{
    if (!itemsPerPage.value) return 0;
    return itemsPerPage.value * page.value - itemsPerPage.value;
  });
  const isLastPage = computed(()=>{
    return numberOfPages.value === page.value || !numberOfPages;
  });
  return {
    page,
    itemsPerPage,
    numberOfPages,
    offsetPosition,
    isLastPage,
  };
};


export const usePaginatedSearch = () => {
  const {page, itemsPerPage, offsetPosition} = usePagination();
  const isSearching = ref(false);
  const isLastPage = ref(false);
  const searchText = ref('');
  const debouncedSearchText = ref('');
  const items = ref([]);
  const noDataAvailable = computed(()=>{
    return isLastPage.value && items.value.length === 0 && debouncedSearchText.value === '' && !isSearching.value;
  });
  const immediateSearch = () =>{
    isSearching.value = true;
    page.value = 1;
    debouncedSearchText.value = searchText.value;
  };
  const loadNextPage = () => {
    if (isLastPage.value || isSearching.value) return;
    page.value ++;
  };
  watch(searchText, debounce(immediateSearch, 500));
  const setItemsPage = (itemsPage)=> {
    if (isSearching.value) {
      items.value = [];
    }
    isLastPage.value = itemsPage.length < itemsPerPage.value;
    items.value.push(...itemsPage);
    isSearching.value = false;
  };
  return {
    noDataAvailable,
    searchText,
    debouncedSearchText,
    items,
    pagination: {
      page,
      itemsPerPage,
      offsetPosition,
      isLastPage,
    },
    loadNextPage,
    immediateSearch,
    setItemsPage,
  };
};
