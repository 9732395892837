import {ACTIONS, RESOURCES} from '@/utils/enums';

const {WORKSPACE, PAGES, WORKSPACE_INTEGRATION} = RESOURCES;

const {
  VIEW,
  UPDATE,
  DELETE,
  ADD,
  LEAVE,
} = ACTIONS;

export default ()=>{
  return [
    {
      subject: WORKSPACE_INTEGRATION,
      action: [DELETE, ADD],
    },
    {
      subject: PAGES.WORKSPACE.SETTINGS.BILLING,
      action: [UPDATE],
    },
    {
      subject: WORKSPACE,
      action: [LEAVE],
      inverted: true,
    },
    {
      subject: WORKSPACE,
      action: [DELETE],
    },
    {
      subject: PAGES.WORKSPACE.SETTINGS.PERMISSIONS,
      action: [VIEW, UPDATE],
    },
  ];
};
