/**
 * Middleware to verify the user is authenticated
 * otherwise it will be redirected to the login route.
 * @param {array} parentRoutes - Array with all the
 * parent routes of the current route
 * @return {object} an array with all the middleware of the parent routes with
 * the option applyToChildren enabled
 **/
export function getParentsMiddleware(parentRoutes) {
  const middleware = [];
  for (const parentRoute of parentRoutes) {
    if (parentRoute.meta.applyToChildren) {
      middleware.push(...parentRoute.meta.middleware);
    }
  }
  return middleware;
}
