<template>
  <v-app>
    <vue-progress-bar />
    <base-snackbar />
    <update-pwa />
    <main>
      <transition mode="out-in">
        <router-view />
      </transition>
    </main>
  </v-app>
</template>


<script>
import BaseSnackbar from '@/components/core/dashboard/Snackbar';
import UpdatePwa from '@/components/updatePwa';
export default {
  name: 'App',
  components: {UpdatePwa, BaseSnackbar},
};
</script>
<style lang="scss">
  @import "~@/styles/main.scss";
</style>
