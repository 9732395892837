import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import {auth, guest, unverifiedEmailUser} from './middleware/auth';
import {addIntegration} from './middleware/user';
import {emailActionHandler} from './middleware/firebase/emailHandler';
import middlewarePipeline from './middlewarePipeline';
import {getParentsMiddleware} from '../utils/middleware';
import {modalBeforeEnter} from '@/router/helpers';
import {validateTabs} from '@/router/middleware/modal';
import {USER_SETTING_TABS} from '@/utils/modal';
import {permission} from '@/router/middleware/permissions';
import {
  activeWorkspaceValidator,
  workspaceParameterValidator,
} from '@/router/validators/workspace';
import {RESOURCES} from '@/utils/enums';
import {projectExists} from './middleware/project';
import {workspaceInvitationHandler} from '@/router/middleware/workspace-invitation';

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter);
}

export const MODAL_URLS = {
  WORKSPACE_SETTINGS: '/workspace/:workspaceId(\\d+)/:activeTab',
  PROFILE: '/profile/:activeTab',
  PROJECT_SETTINGS: '/project/:projectId(\\d+)/:activeTab',
};

export const routes = [
  {
    path: '/integration-successful',
    name: 'integration-successful',
    meta: {middleware: [auth, addIntegration]},
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../components/auth/Logout'),
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    alias: '/',
    meta: {middleware: [guest]},
    component: () => import('../views/auth/SignIn'),
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    meta: {middleware: [guest]},
    component: () => import('../views/auth/SignUp'),
  },
  {
    path: '/workspace-invitation',
    name: 'workspace-invitation',
    meta: {middleware: [guest]},
    component: () => import('../views/auth/WorkspaceInvitation'),
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    meta: {middleware: [unverifiedEmailUser]},
    component: () => import('../views/auth/VerifyEmail'),
  },
  {
    path: '/email-handler',
    name: 'email-handler',
    meta: {middleware: [emailActionHandler]},
  },
  {
    path: '/workspace-invitation-email',
    name: 'workspace-invitation-email',
    meta: {middleware: [workspaceInvitationHandler]},
  },
  {
    path: '/verify-email-success',
    name: 'verify-email-success',
    meta: {middleware: [auth]},
    component: () => import('../views/auth/VerifyEmailSuccess'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {middleware: [guest]},
    component: () => import('../views/auth/ResetPassword'),
  },
  {
    path: '/reset-password-confirm',
    name: 'reset-password-confirm',
    meta: {middleware: [guest]},
    component: () => import('../views/auth/ResetPasswordConfirm'),
  },
  {
    path: '/dashboard',
    meta: {middleware: [auth], applyToChildren: true},
    component: () => import('../views/Dashboard'),
    children: [
      {
        path: '/home',
        name: 'dashboard',
        component: () => import('../views/time-entry/TimeEntryView'),
      },
      {
        path: '/time-entries',
        name: 'time-entries',
        component: () => import('../views/time-entry/TimeEntryView'),
      },
      {
        path: '/tasks',
        name: 'tasks',
        component: () => import('../views/task/TaskList'),
      },
      // Dashboards
      {
        path: '/dashboards',
        name: 'dashboards',
        component: () => import('../views/dashboards/DashboardView'),
      },
      // Workspace
      {
        path: '/workspaces',
        name: 'workspaces',
        component: () => import('../views/workspace/WorkspaceList'),
      },
      {
        path: '/workspace/create',
        name: 'create-workspace',
        components: {
          modal: () => import('../views/workspace/CreateWorkspace'),
        },
        beforeEnter: modalBeforeEnter,
      },
      {
        path: MODAL_URLS.WORKSPACE_SETTINGS,
        name: 'workspace-settings-tab',
        meta: {
          getPageResource(to) {
            const {activeTab} = to.params;
            return RESOURCES.PAGES.WORKSPACE.SETTINGS[activeTab];
          },
          middleware: [permission(workspaceParameterValidator)],
        },
        component: () => import('../views/workspace/WorkspaceSettings'),
        props: (route) => ({workspaceId: Number(route.params.workspaceId)}),
      },
      {
        path: '/workspace/:workspaceId',
        name: 'workspace-settings',
        redirect: '/workspace/:workspaceId/basic_settings',
      },
      // Projects
      {
        path: '/projects',
        name: 'projects',
        component: () => import('../views/project/ProjectList'),
      },
      {
        path: '/project/create',
        name: 'create-project',
        meta: {
          getPageResource: () => RESOURCES.PAGES.PROJECT.CREATE,
          middleware: [permission(activeWorkspaceValidator)],
        },
        components: {
          modal: () => import('../views/project/CreateProject'),
        },
        beforeEnter: modalBeforeEnter,
      },
      {
        path: MODAL_URLS.PROJECT_SETTINGS,
        name: 'project-settings-tab',
        meta: {
          getPageResource(to) {
            const {activeTab} = to.params;
            return RESOURCES.PAGES.PROJECT[activeTab];
          },
          middleware: [permission(activeWorkspaceValidator), projectExists],
        },
        component: () => import('../views/project/ProjectSettings'),
        props: (route) => ({
          projectId: Number(route.params.projectId),
        }),
      },
      {
        path: '/project/:projectId',
        name: 'project-settings',
        redirect: '/project/:projectId/basic_settings',
      },
      // Time Interval
      {
        path: '/time-interval/create',
        name: 'create-time-interval',
        components: {
          modal: () => import('../views/time-interval/CreateTimeInterval'),
        },
        beforeEnter: modalBeforeEnter,
      },
      // Time Entry
      {
        path: '/time-entry/:timeEntryId(\\d+)',
        name: 'time-entry-detail',
        props: {
          modal: (route) => ({
            timeEntryId: Number(route.params.timeEntryId),
          }),
        },
        components: {
          modal: () => import('../views/time-entry/TimeEntryDetail'),
        },
        beforeEnter: modalBeforeEnter,
      },
      // User
      {
        path: MODAL_URLS.PROFILE,
        name: 'profile',
        meta: {middleware: [validateTabs(USER_SETTING_TABS)]},
        component: () => import('../views/user/UserSettings'),
      },
      {
        path: '/profile',
        redirect: '/profile/basic_settings',
      },
      {
        path: '/reports',
        name: 'reports',
        component: () => import('../views/reports/Reports.vue'),
      },
    ],
  },
  {
    path: '/not-found',
    component: () => import('../views/errors/NotFound'),
  },
  {
    path: '/forbidden',
    component: () => import('../views/errors/Forbidden'),
  },
  {
    path: '*',
    redirect: '/not-found',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach((to, from, next) => {
  const parentRoutes = to.matched.slice(0, to.matched.length - 1);
  const middleware = getParentsMiddleware(parentRoutes);
  if (to.meta.middleware) middleware.push(...to.meta.middleware);
  if (middleware.length === 0) {
    return next();
  }
  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});
export default router;
