import {CONSTRAINTS} from '@/utils/graphql/constraints';
import fetch from 'node-fetch';
import {HttpLink} from 'apollo-link-http';
import {ApolloLink, from} from 'apollo-link';
import {setAuthHeaders} from '@/utils/graphql';
import {RetryLink} from 'apollo-link-retry';
import store from '@/store';

export const CONSTRAINT_VIOLATION = 'constraint-violation';
export const INVALID_JWT = 'invalid-jwt';
const HTTP_ENDPOINT = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql';

const cleanTypeName = new ApolloLink(
    (operation, forward) => {
      if (operation.variables) {
        const omitTypename = (key, value) =>
          (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(
            JSON.stringify(operation.variables),
            omitTypename);
      }
      return forward(operation).map((data) => {
        return data;
      });
    });

const auth = new ApolloLink((operation, forward) => {
  setAuthHeaders(operation);
  return forward(operation);
});

const retryLink = new RetryLink({
  attempts: async (count, operation, error) => {
    if (error.message !== INVALID_JWT || count > 1) return false;
    await store.dispatch('auth/refreshToken');
    setAuthHeaders(operation);
    return true;
  },
});

const errorHandlerLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response && response.errors && response.errors.length > 0) {
      for (const {message, extensions} of response.errors) {
        switch (extensions.code) {
          case INVALID_JWT:
            throw new Error(INVALID_JWT);
          case CONSTRAINT_VIOLATION:
            const violatedConstraint = Object.keys(CONSTRAINTS).find(
                (constraint)=>message.indexOf(constraint) > 0);
            const constraint =
              CONSTRAINTS[violatedConstraint] ||
              CONSTRAINTS.default;
            response.errors = [
              {...constraint, message, addSnackbar: true},
            ];
            break;
          default:
            response.errors = [
              {code: extensions.code, message, addSnackbar: true},
            ];
        }
      }
    }
    return response;
  });
});

const httpLink = new HttpLink({uri: HTTP_ENDPOINT, fetch});


const FINAL_HTTP_LINK = from([
  cleanTypeName,
  auth,
  retryLink,
  errorHandlerLink,
  httpLink,
]);

export default FINAL_HTTP_LINK;
