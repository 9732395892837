import Vue from 'vue';

const getDefaultState = ()=> ({
  messages: {},
});

const mutations = {
  REMOVE_MESSAGE(state, form) {
    Vue.set(state.messages, form, null);
  },
  SET_SUCCESS(state, {form, message, params}) {
    Vue.set(state.messages, form, {text: message, isSuccess: true, params});
  },
  SET_ERROR(state, {form, message, params}) {
    Vue.set(state.messages, form, {text: message, isSuccess: false, params});
  },
};

const actions = {
  popMessage({commit, state}, form) {
    const message = {...state.messages[form]};
    commit('REMOVE_MESSAGE', form);
    return message;
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
  state: getDefaultState(),
  getDefaultState,
};
