const files = require.context('.', false, /\.js$/);
export const grammaticalGenders = {};
const translations = {};

files.keys().forEach((fileName) => {
  if (fileName === './index.js') return;
  const name = fileName.replace(/(\.\/|\.js)/g, '');
  translations[name] = files(fileName).default;
  grammaticalGenders[name] = files(fileName).grammaticalGender;
});

export default translations;
