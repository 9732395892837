import Vue from 'vue';

const getDefaultState = () => ({
  isExpanded: true,
  loadingOperations: {},
  initialOperations: {
    getUserWorkspaces: false,
  },
});

const mutations = {
  TOGGLE_DRAWER(state) {
    state.isExpanded = !state.isExpanded;
  },
  START_OPERATION_LOAD(state, operationName) {
    Vue.set(state.loadingOperations, operationName, true);
  },
  COMPLETE_OPERATION_LOAD(state, operationName) {
    Vue.delete(state.loadingOperations, operationName);
    state.initialOperations[operationName] = true;
  },
};

const getters = {
  isInitialOperationCompleted(state) {
    return Object.values(state.initialOperations)
        .every((isLoaded)=>isLoaded);
  },
  isInitialLoadCompleted(state, getters, rootState) {
    return getters.isInitialOperationCompleted &&
      !!rootState.workspace.activeWorkspaceId;
  },
};

export default {
  namespaced: true,
  mutations,
  getters,
  state: getDefaultState(),
  getDefaultState,
};
