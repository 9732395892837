import {TIMELINE_ACTIVITY_RANGE} from '@/utils/time-entry';

const name = 'time_entry';
export default {
  name,
  resolvers: {
    start_time_date({start_time}) {
      return new Date(start_time);
    },
    stop_time_date({stop_time}) {
      return new Date(stop_time);
    },
    activity_color({activity}) {
      for (const {color, min, max} of TIMELINE_ACTIVITY_RANGE) {
        if (activity >= min && activity < max) return color;
      }
    },
    screenshot_url({screenshots}) {
      if (screenshots.length === 0 ) return '';
      return screenshots[0].thumbnail;
    },
  },
};
