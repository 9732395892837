import axios from 'axios';
import {verifyUserEmail} from '@/graphql/user.gql';
import {inviteMembersToWorkspace} from '@/graphql/workspace.gql';
import apolloClient from '@/apollo/client';

const {VUE_APP_BACKEND_API} = process.env;

const API_URLS = {
  INTEGRATION_OAUTH_HANDLER: '/integration/oauth-authorization',
  INTEGRATION_PROXY: '/integration/proxy',
};

const handler = {
  get: function(obj, prop) {
    if (!(prop in obj)) throw new Error(`Unknown url=${prop}`);
    return `${VUE_APP_BACKEND_API}${obj[prop]}`;
  },
};

export class Api {
  baseUrl;
  http;
  constructor(baseUrl = VUE_APP_BACKEND_API) {
    this.baseUrl = baseUrl;
    this.http = axios.create({
      baseURL: baseUrl,
    });
    this.http.interceptors.request.use((config)=>{
      const token = localStorage.getItem('access_token');
      if (!!token) {
        config.headers.authorization = `Bearer ${token}`;
      }
      return config;
    });
  }

  verifyEmail() {
    return apolloClient.mutate({
      mutation: verifyUserEmail,
    });
  }

  sendWorkspaceInvitation(payload) {
    return apolloClient.mutate({
      mutation: inviteMembersToWorkspace,
      variables: payload,
    });
  }
}

export const URLS = new Proxy(API_URLS, handler);
