import Vue from 'vue';

const getDefaultState = ()=>( {
  filters: {},
  loadNextPage: null,
  search: {text: '', option: null},
  isMeMode: false,
});

const mutations = {
  SET_LOAD_NEXT_PAGE(state, payload) {
    state.loadNextPage = payload;
  },
  SET_SEARCH(state, payload) {
    state.search = payload;
  },
  SET_FILTER(state, {filterName, value}) {
    Vue.set(state.filters, filterName, value);
  },
  CLEAN_FILTERS(state) {
    state.filters = {};
  },
  SET_ME_MODE(state, isMeMode) {
    state.isMeMode = isMeMode;
  },
};

const getters = {
  searchMenu(state) {
    return state.search;
  },
  selectedWorkspaceId(state, getters, rootState) {
    return state.isMeMode && rootState.workspace.activeWorkspace.personal ? null : rootState.workspace.activeWorkspaceId;
  },
  selectedWorkspaceHasuraFilter(state, getters) {
    return getters.selectedWorkspaceId ? {workspace_id: {_eq: getters.selectedWorkspaceId}} : {};
  },
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  getters,
  getDefaultState,
};
