import Vue from 'vue';
import moment from 'moment';
import 'moment-duration-format';
import {sanitizeUrl} from '@braintree/sanitize-url';

const filters = {
  capitalize,
  humanTime: durationToHumanTime,
  dateHours,
  sanitize,
  humanTimeHours: durationToHumanTimeHours,
};
/**
 * Capitalize a string
 *  @param {String} value - String to capitalize
 *  @return {String} capitalized string
 */
export function capitalize(value) {
  if (!value) return '';
  value = value.toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
}
/**
 * Convert duration in milliseconds to a string formatted by hours and minutes
 * @param {Integer} duration
 * @return {String} Formatted duration
 */
export function durationToHumanTime(duration) {
  return moment.duration(duration).format('h[h] m[m]');
}

/**
 * Receives a timestamp and an hour format and return a string with the
 * hours of the timestamp formatted as requested.
 * @param {Date | number} timestamp - Timestamp to format the hours
 * @param {String} timeFormat - Time format used to format the hours,
 * allowed values: '12h' or '24h'
 * @return {string}
 */
export function dateHours(timestamp, timeFormat) {
  const formats = {
    '12h': 'h:mm A',
    '24h': 'HH:mm',
  };
  return moment(timestamp).format(formats[timeFormat]);
}
/**
 * Sanitize a URL string
 *  @param {String} value - String to sanitize
 *  @return {String} sanitize string
 */
export function sanitize(value) {
  return sanitizeUrl(value);
}

/**
 * Transforms duration in hours to string h[h] m[m]
 * @param {Date} time - String to truncate
 * @return {String}
 */export function durationToHumanTimeHours(time) {
  return moment.duration(time, 'hours').format('h[h] m[m]');
}

/**
 * Truncate a string to match a given length
 * @param {String} str - String to truncate
 * @param {Number} n - Total characters
 * @return {String}
 */
export function truncate(str, n) {
  return (str.length > n) ? str.substring(0, n) + '...' : str;
}

Object.keys( filters ).forEach( (key) => {
  Vue.filter( key, filters[key] );
});
