import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import apollo from '@/apollo/client';
import {getInitials} from '@/utils/user';
import {promisify} from '@/utils';
import emailHandler from './email-handler';
import {
  clearRequirePasswordChange,
  getUser,
  updateUser,
} from '@/graphql/user.gql';
import {AuthProviders} from '@/firebase';
import {clearAbilityCache} from '@/permissions/workspace';
import {closeWebsocket} from '@/apollo/client/links/websocket';

const getDefaultState = ()=> ({
  user: {
    id: null,
    full_name: '',
    email: '',
    initials: '',
    photo: null,
    provider_token: null,
    is_authenticated: false,
    is_verified: false,
    require_change_password: false,
  },
});

const mutations = {
  UPDATE_USER(state, userData) {
    state.user = {...state.user, ...userData};
  },
  SIGN_IN(state, user) {
    state.user = user;
    state.user.initials = getInitials(user.full_name);
    state.user.is_authenticated = true;
  },
  UPDATE_JWT(state, jwt) {
    localStorage.setItem('access_token', jwt);
  },
  LOGOUT(state) {
    state.user = getDefaultState().user;
    state.user.is_authenticated = false;
  },
};

const actions = {
  async getOrCreateHasuraUser({commit, state}) {
    const {user} = state;
    const {id, email} = user;
    let userHasura = await apollo.query({
      query: getUser,
      variables: {id, email},
      fetchPolicy: 'network-only',
    }).then((response)=>response.data.user[0]);
    if (!userHasura || !userHasura.email_verified) {
      await this._vm.$api.verifyEmail();
      userHasura = await apollo.query({
        query: getUser,
        variables: {id, email},
        fetchPolicy: 'network-only',
      }).then((response) => response.data.user[0]);
      if (!userHasura) throw new Error('hasura_user_not_created');
      if (!userHasura.email_verified) {
        throw new Error('hasura_user_not_verified');
      }
    }
    commit('UPDATE_USER', {
      username: userHasura.username,
      require_change_password: userHasura.require_change_password,
    });
  },
  async cleanRequireChangePassword({state}) {
    if (!state.user.require_change_password) return;
    await apollo.mutate({
      mutation: clearRequirePasswordChange,
      variables: {
        id: state.user.id,
      },
    });
  },
  async refreshToken({commit}, forceRefresh = true) {
    const user = await firebase.auth().currentUser;
    const newToken = await user.getIdToken(forceRefresh);
    commit('UPDATE_JWT', newToken);
  },
  async baseSignIn({commit, dispatch, state}, {user, providerToken}) {
    try {
      let token = await user.getIdToken();
      const idTokenResult = await user.getIdTokenResult();
      const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];
      const userData = {
        id: user.uid,
        email: user.email,
        full_name: user.displayName,
        is_verified: user.emailVerified,
        provider_token: providerToken,
      };
      if (!hasuraClaim) {
        // Check if refresh is required.
        const metadataRef = (await firebase.database()).
            ref(`metadata/${user.uid}/refreshTime`);
        const promisifyRef = promisify(metadataRef.on, metadataRef);
        await promisifyRef('value');
        token = await user.getIdToken(true);
      }
      commit('SIGN_IN', userData);
      commit('UPDATE_JWT', token);
      if (userData.is_verified) {
        await dispatch('getOrCreateHasuraUser');
        await dispatch('cleanRequireChangePassword');
        await dispatch('getProfilePhoto');
      }
    } catch (e) {
      await dispatch('logOut');
      throw e;
    }
  },
  async signUp({dispatch}, data) {
    const {email, password, full_name} = data;
    const {user} = (await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password));
    await user.updateProfile({
      displayName: full_name,
    });
    await dispatch('baseSignIn', {user});
    if (!user.is_verified) {
      await user.sendEmailVerification();
    }
  },
  async signIn({dispatch}, data) {
    const {email, password} = data;
    const {user} = (await firebase
        .auth()
        .signInWithEmailAndPassword(email, password));
    await dispatch('baseSignIn', {user});
  },
  async signInWithProvider({dispatch}, data = {}) {
    const {providerName} = data;
    const provider = new AuthProviders[providerName].constructor();
    provider.setCustomParameters({
      'login_hint': 'user@example.com',
    });
    const {user, credential} = await firebase
        .auth()
        .signInWithPopup(provider);
    const providerToken = credential.accessToken;
    await dispatch('baseSignIn', {user, providerToken});
  },
  async logOut({commit}) {
    this._vm.$integrations.clear();
    clearAbilityCache();
    await closeWebsocket();
    await apollo.resetStore();
    commit('LOGOUT');
    await firebase
        .auth()
        .signOut();
    commit('RESET', null, {root: true});
    localStorage.clear();
  },
  async updateProfilePhoto({commit, state}, {photo, onlyFromFirebase = false}) {
    const {user} = state;
    const storageRef = firebase.storage()
        .ref(`users/${state.user.id}/profile_picture/profile.jpg`);
    let photoUrl = null;
    if (photo === null) {
      commit('UPDATE_USER', {photo: null});
      await storageRef.delete();
    } else {
      const task = await storageRef.putString(photo, 'data_url');
      photoUrl = await task.ref.getDownloadURL();
      commit('UPDATE_USER', {photo: photoUrl});
    }
    if (!onlyFromFirebase) {
      await apollo.mutate({
        mutation: updateUser,
        variables: {changes: {photo: photoUrl}, id: user.id},
      });
    }
  },
  async updateUser({commit, dispatch, state}, {photo, ...data}) {
    const {full_name, username} = data;
    const newData = {username, full_name};
    const {user} = state;
    const firebaseUser = await firebase
        .auth().currentUser;
    await apollo.mutate({
      mutation: updateUser,
      variables: {changes: newData, id: user.id},
    });
    await firebaseUser.updateProfile({
      displayName: full_name,
    });
    if (photo && photo !== user.photo) {
      await dispatch('updateProfilePhoto', {photo});
    }
    commit('UPDATE_USER', newData);
  },
  async getProfilePhoto({commit, state}) {
    let photo = null;
    try {
      const profileRef = firebase.storage()
          .ref(`users/${state.user.id}/profile_picture/profile.jpg`);
      const profilePromise = profileRef.getDownloadURL();

      photo = await profilePromise;
    } catch (e) {}
    commit('UPDATE_USER', {photo});
  },
};

const getters = {
  isAuthorized: (state)=>{
    return state.user.is_authenticated && state.user.is_verified;
  },
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: getDefaultState(),
  modules: {emailHandler},
  getDefaultState,
};
