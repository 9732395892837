import {FIELDS} from '@/utils/enums';
import {RESOURCES, ACTIONS} from '@/utils/enums';
const {TASK} = RESOURCES;
const {UPDATE} = ACTIONS;

export default ()=>{
  return [
    {
      subject: TASK,
      fields: [FIELDS.TASK.TIME_ESTIMATE],
      conditions: {
        'project.formatted_settings.can_member_estimate': true,
      },
      action: UPDATE,
    },
    {
      subject: TASK,
      fields: [FIELDS.TASK.COMPLETED],
      conditions: {
        'project.formatted_settings.can_member_complete': true,
      },
      action: UPDATE,
    },
  ];
};
