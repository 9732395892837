/**
 * Navigation guard to allow any modal keeping the origin route's page intact
 * also, it sets a flag `directAccess` in the destination route
 * if the route is being access directly (No redirection inside the app)
 * @param {Object} to - Destination route
 * @param {Object} from - Origin route
 * @param {Function} next - Function to redirect the user
 * @return {Promise<void>}
 */
export async function modalBeforeEnter(to, from, next) {
  const isDirectAccess = from.matched.length === 0;
  const destinationBackground = to.matched[to.matched.length - 1];
  if (isDirectAccess) {
    to.meta.directAccess = true;
    const home = await import('@/views/time-entry/TimeEntryView');
    destinationBackground.components.default = home.default;
  } else {
    const originBackground = from.matched[from.matched.length - 1];
    destinationBackground.components.default =
      originBackground.components.default;
  }
  next();
}
