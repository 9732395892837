/** Custom error with translation params
 * */
export default class TranslatableError extends Error {
  /**
   * @param {string} code - Error code, usually something like: `wrong_password`
   * @param {object | array} params - Translation parameters that
   * will be used in the frontend message
   * */
  constructor(code, params = []) {
    super(code);
    this.code = code;
    this.params = params;
  }
}
/** Custom snackbar with translation params
 * */
export class SnackbarError extends Error {
  /**
   * @param {object | array} params - Message parameters
   * */
  constructor(...params) {
    super(...params);
    this.addSnackbar = true;
  }
}
