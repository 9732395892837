import {grammaticalGenders} from '@/locales';
import i18n from '@/plugins/i18n';

export const LANG_KEY = 'lang';

export const getLanguage = () =>{
  return window.localStorage.getItem(LANG_KEY) ||
      window.navigator.language.slice(0, 2) ||
      process.env.VUE_APP_I18N_LOCALE ||
      'en';
};

export const getGrammaticalGender = (message) =>{
  const lang = getLanguage();
  return grammaticalGenders[lang][message];
};

export const tsg = (message, subject, key = 'subject') => {
  const gender = getGrammaticalGender(subject);
  return {text: message, params: {[key]: i18n.tc(subject, 1), gender}};
};

export const tg = (message) => {
  const gender = getGrammaticalGender(message);
  return i18n.t(message, {gender});
};

export const getSuccessMessage =
  (subject, mode, params = {}) => {
    params.gender = getGrammaticalGender(subject);
    params.subject = i18n.tc(subject, 1);
    const messages = {
      'create': 'subject_added',
      'edit': 'subject_updated',
      'delete': 'subject_removed',
    };
    return {text: messages[mode], params};
  };
