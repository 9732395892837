export default class ClickUpMapper {
  static mapWorkspaceFromApi(clickUpWorkspace) {
    const clickUpMembers = clickUpWorkspace.members || [];
    return {
      id: clickUpWorkspace.id,
      name: clickUpWorkspace.name,
      members: clickUpMembers.map(ClickUpMapper.mapMemberFromApi),
    };
  }

  static mapProjectFromApi(clickUpProject) {
    return {
      id: clickUpProject.id,
      name: clickUpProject.name,
    };
  }

  static mapMemberFromApi(clickUpMember) {
    return {
      user: {
        id: clickUpMember.user.id,
        name: clickUpMember.user.username,
        email: clickUpMember.user.email,
      },
    };
  }
}
