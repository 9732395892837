export const getHeaders = () => {
  const token = localStorage.getItem('access_token');
  if (!!token) {
    return {
      'authorization': `Bearer ${token}`,
      'X-Hasura-Role': 'user',
    };
  }
  return {};
};

export const setAuthHeaders = (operation)=>{
  operation.setContext({
    headers: getHeaders(),
  });
};


export const saveSubscriptionResult = function({data}, key) {
  // eslint-disable-next-line no-invalid-this
  this[key] = data[key];
};

export const isConstraintError = ({constraint, error}) => {
  return error.graphQLErrors[0].code === constraint.code;
};
