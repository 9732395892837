import {ApolloLink} from 'apollo-link';
import Observable from 'zen-observable';
import store from '@/store';

const initialSubscriptionLoadMiddleware = {
  start(operation) {
    store.commit('app/START_OPERATION_LOAD', operation.operationName);
  },
  next(operation, value) {
    store.commit('app/COMPLETE_OPERATION_LOAD', operation.operationName);
    return value;
  },
  error(operation, value) {
    store.commit('app/COMPLETE_OPERATION_LOAD', operation.operationName);
    return value;
  },
};

const middlewareChain = [initialSubscriptionLoadMiddleware];

/**
 * Applies the middlewareChain array which contains a handler method for
 * next and error values of a subscription
 *
 * @param {'start'|'next'| 'error'} middlewareKey -
 * The middleware handler to apply to the original value
 * @param {Object} operation - The apollo operation
 * @param {any=} originalValue - The original value which will be passed to
 * every middleware handler
 * @return {Object} The final result value modified by every middleware handler
 */
function applyMiddlewareChain(middlewareKey, operation, originalValue) {
  let value = originalValue;
  for (const middleware of middlewareChain) {
    value = middleware[middlewareKey](operation, value);
  }
  return value;
}

export const WS_MIDDLEWARE_WRAPPER = new ApolloLink( (operation, observe) => {
  return new Observable((observer) => {
    applyMiddlewareChain('start', operation);
    const _subscription = observe(operation).subscribe({
      next(rawValue) {
        observer.next(
            applyMiddlewareChain('next', operation, rawValue)
        );
      },
      complete() {
        unsubscribe();
      },
      async error(rawError) {
        observer.error(
            applyMiddlewareChain('error', operation, rawError)
        );
      },
    });
    const unsubscribe = () => {
      _subscription.unsubscribe();
    };

    return unsubscribe;
  });
});
