export const MEMBER_ROLE = {
  OWNER: 'Owner',
  MEMBER: 'Member',
  ADMIN: 'Admin',
};

export const MEMBER_STATUS = {
  INVITED: 'invited',
  UNINVITED: 'uninvited',
  ACTIVE: 'active',
};
