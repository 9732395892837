import {ACTIONS, RESOURCES} from '@/utils/enums';

const {WORKSPACE, WORKSPACE_INTEGRATION} = RESOURCES;

const {
  ADD,
  LEAVE,
} = ACTIONS;

export default ()=>{
  return [
    {
      subject: WORKSPACE,
      action: [LEAVE],
    },
    {
      subject: WORKSPACE_INTEGRATION,
      action: ADD,
      reason: 'only_owners_can_add_integrations',
      inverted: true,
    },
  ];
};
