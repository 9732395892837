import axios from 'axios';
import {URLS} from '@/services/Api';
import ClickUpMapper from '@/services/integrations/clickup/ClickUpMapper';

export class ClickUpApi {
  apiUrl;
  http;
  integrationUserId;
  constructor(apiUrl, token, integrationUserId) {
    this.apiUrl = apiUrl;
    this.integrationUserId = integrationUserId;
    this.http = axios.create({
      baseURL: URLS.INTEGRATION_PROXY,
      headers: {
        authorization: token,
      },
    });
  }

  url(url) {
    return {
      'target-url': `${this.apiUrl}${url}`,
    };
  }

  get Workspaces() {
    const api = this;
    return {
      async getAll() {
        const clickUpWorkspaces = await api.http.get('', {
          headers: api.url('/team'),
        }).then(({data})=>data.teams);
        return clickUpWorkspaces.map(ClickUpMapper.mapWorkspaceFromApi);
      },
      async getById(id) {
        return this.getAll()
            .then(
                (workspaces)=>
                  workspaces.find((workspace)=>workspace.id === id));
      },
    };
  }

  get Projects() {
    const api = this;
    return {
      async getAll(workspaceId) {
        const clickUpProjects = await api.http.get('', {
          headers: api.url(`/team/${workspaceId}/space?archived=false`),
        }).then(({data})=>data.spaces);
        return clickUpProjects.map(ClickUpMapper.mapProjectFromApi);
      },
    };
  }

  get Members() {
    const api = this;
    return {
      async getAll(workspaceId) {
        const workspace = await api.Workspaces.getById(workspaceId);
        return workspace.members;
      },
    };
  }
}
