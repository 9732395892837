import axios from 'axios';
import apolloClient from '@/apollo/client';
import {URLS} from '@/services/Api';
import AsanaMapper from '@/services/integrations/asana/AsanaMapper';
import {refreshAuthorizationToken} from '@/graphql/integration_user.gql';

export class AsanaApi {
  apiUrl;
  http;
  integrationUserId;
  constructor(apiUrl, token, integrationUserId) {
    this.apiUrl = apiUrl;
    this.integrationUserId = integrationUserId;
    this.http = axios.create({
      baseURL: URLS.INTEGRATION_PROXY,
      headers: {
        authorization: token,
      },
    });

    this.http.interceptors.response.use(
        (response) => response,
        async (error) => this.handleIntegrationRequestError(error)
    );
  }

  async handleIntegrationRequestError(error) {
    const originalRequest = error.config;
    if (this.isUnauthorizedTokenError(error) && !originalRequest._retry) {
      originalRequest._retry = true;
      const accessToken = await this.refreshAuthorizationToken();
      if (accessToken) {
        delete this.http.defaults.headers.authorization;
        this.http.defaults.headers.authorization = accessToken;
        originalRequest.headers['authorization'] = accessToken;
        return this.http.request(originalRequest);
      }
    }
  }

  isUnauthorizedTokenError(error) {
    return error.response.status === 401;
  }

  async refreshAuthorizationToken() {
    const {data} = await apolloClient.mutate({
      mutation: refreshAuthorizationToken,
      variables: {
        integration_user_id: this.integrationUserId,
      },
    });

    if (!data) return '';
    const {refresh_authorization_token: response} = data;
    if (!response || !response.success) return '';
    return response.token || '';
  }

  url(url) {
    return {
      'target-url': `${this.apiUrl}${url}`,
    };
  }

  get Workspaces() {
    const api = this;
    return {
      async getAll() {
        const asanaWorkspaces = await api.http.get('', {
          headers: api.url('/workspaces'),
        }).then(({data})=>data.data);
        return asanaWorkspaces.map(AsanaMapper.mapWorkspaceFromApi);
      },
      async getById(id) {
        const asanaWorkspace = await api.http.get('', {
          headers: api.url(`/workspaces/${id}`),
        }).then(({data})=>data.data);
        return AsanaMapper.mapWorkspaceFromApi(asanaWorkspace);
      },
    };
  }

  get Projects() {
    const api = this;
    return {
      async getAll(workspaceId) {
        const asanaProjects = await api.http.get('', {
          headers: api.url(`/projects?workspace=${workspaceId}&archived=false`),
        }).then(({data})=>data.data);
        return asanaProjects.map(AsanaMapper.mapProjectFromApi);
      },
    };
  }

  get Members() {
    const api = this;
    return {
      async getAll(workspaceId) {
        const asanaMembers = await api.http.get('', {
          headers: api.url(`/users?workspace=${workspaceId}&opt_fields=email,name`),
        }).then(({data})=>data.data);
        return asanaMembers.map(AsanaMapper.mapMemberFromApi);
      },
    };
  }
}
