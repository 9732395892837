import i18n from '@/plugins/i18n';

/**
 * This is a function will allow you to wrap another function
 * which receives a callback
 * to return a promise instead
 *
 * @param {function} fn - the function that will be wrapped
 * @param {object} current - instance that will be passed as this to the
 * wrapped function
 * @return {function} Wrapped function that will return a promise
 */
export function promisify(fn, current) {
  return (...args) => {
    return new Promise((resolve) => {
      const callback = (result) => {
        return resolve(result);
      };
      args.push(callback);
      // eslint-disable-next-line no-invalid-this
      fn.call(current || this, ...args);
    });
  };
}
/**
 * This is a function will allow you to generate a pseudo random 40 char string
 *
 * @return {string} Pseudo random generated string
 */
export function getRandomNonce() {
  const arrayValidChars = [
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    'abcdefghijklmnopqrstuvwxyz',
    '0123456789',
  ];
  const validChars = arrayValidChars.join('');
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map((x) => validChars.charCodeAt(x % validChars.length));
  return String.fromCharCode.apply(null, array);
}
/**
 * This is a function will allow you to encode params for a URL
 * @param {object} params - Parameters to encode
 * @param {string} joinCharacter - Character used to join all the parameters
 * @return {string} Encoded params
 */
export function encodeData(params, joinCharacter = '&') {
  return Object.keys(params)
    .map(function (key) {
      return [key, params[key]].map(encodeURIComponent).join('=');
    })
    .join(joinCharacter);
}

/**
 * This function will allow you to encode a url with get params
 * @param {object} url - Base url to encode
 * @param {object} params - Get parameters to encode
 * @return {string} Encoded url
 */
export function encodeUrl(url, params) {
  const encodedParams = encodeData(params);
  return !!encodedParams ? `${url}?${encodedParams}` : url;
}

/**
 * This is a function will allow to open a child window
 * @param {string} url - Url for the new window
 * @param {int} expectedWidth - Expected width for the new window
 * @param {int} expectedHeight - Expected height for the new window
 */
export function openChildWindow(
  url,
  expectedWidth = 800,
  expectedHeight = 600
) {
  const windowOptions = {
    toolbar: 0,
    scrollbars: 1,
    status: 1,
    resizable: 1,
    location: 1,
    menuBar: 0,
  };
  const windowArea = calculateWindowPosition(
    window,
    expectedWidth,
    expectedHeight
  );
  const windowOpts = encodeData({...windowOptions, ...windowArea}, ',');
  window.open(url, '', windowOpts);
}
/**
 * This function will calculate the position for a new window according to the
 * provided window
 * @param {object} window - The window object to get the correct size
 * @param {int} expectedWidth - Expected width for the new window
 * @param {int} expectedHeight - Expected height for the new window
 * @return {object} Object with top, left, width and height for the new window
 */
export function calculateWindowPosition(window, expectedWidth, expectedHeight) {
  const {width: existingWidth, height: existingHeight} = window.screen;
  const left = existingWidth / 2 - expectedWidth / 2;
  const top = existingHeight / 2 - expectedHeight / 2;

  const width = Math.min(expectedWidth, existingWidth);
  const height = Math.min(expectedHeight, existingHeight);

  return {
    left: Math.max(left, 0),
    top: Math.max(top, 0),
    width,
    height,
  };
}
/**
 * This function will download an image and return it as a base 64 string
 * provided window
 * @param {string} imageUrl - The image url
 * @return {object} Object with top, left, width and height for the new window
 */
export async function toDataUrl(imageUrl) {
  const blob = await fetch(imageUrl).then((r) => r.blob());
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
/**
 * This function cast a string to the desired type
 * provided window
 * @param {string} type - Expected cast type
 * @param {string} variable - The variable to cast
 * @return {object} The casted variable
 */
export function castType(type, variable) {
  switch (type) {
    case 'string':
      return variable.toString();
    case 'number':
      return Number(variable);
    case 'boolean':
      return variable === 'true';
    case 'json':
      return JSON.parse(variable);
    default:
      throw new Error('Unsupported type');
  }
}
/**
 * This function cast a variable(string/number/object/array) to string
 * provided window
 * @param {object|string|number} variable - The variable to cast
 * @return {string} The casted variable
 */
export function objectToString(variable) {
  if (typeof variable === 'object' && variable !== null) {
    return JSON.stringify(variable);
  }
  return variable.toString();
}

/**
 * This function will translate all the translatableParams in a successMessage
 * @param {object} successMessage - The success message with optional
 * translatableParams that will be translated
 * @return {object} The success message with the translated parameters
 */
export function translateSuccessMessage(successMessage = {}) {
  const {translatableParams, params = {}, text} = {...successMessage};
  if (translatableParams) {
    const translatedParams = Object.entries(translatableParams).reduce(
      (result, [key, value]) => {
        result[key] = i18n.tc(value, 1);
        return result;
      },
      {}
    );
    Object.assign(params, translatedParams);
  }
  return {isSuccess: true, params, text};
}

/**
 * Allows to perform a deep copy on a particular object
 * @param {Object} object - Object to be copied
 * @return {Object} - Copied object
 */
export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

// eslint-disable-next-line require-jsdoc
export function findPosition({item, prop}, arraySelected) {
  if (!item || !prop || !arraySelected || arraySelected.length === 0) {
    return -1;
  }

  return arraySelected.findIndex((selected) => selected[prop] === item[prop]);
}

// eslint-disable-next-line require-jsdoc
export function removeItems(arrayToBeFiltered, itemsToRemove) {
  if (
    !arrayToBeFiltered ||
    arrayToBeFiltered.length === 0 ||
    !itemsToRemove.prop ||
    itemsToRemove.items.length === 0
  ) {
    return [];
  }
  const {items, prop} = itemsToRemove;
  return arrayToBeFiltered.filter(
    (objectToBeFiltered) =>
      !items.find((item) => item[prop] === objectToBeFiltered[prop])
  );
}

// eslint-disable-next-line require-jsdoc
export function returnExistingValue(value1, value2) {
  if (!value1.data[value1.prop]) {
    if (value2.data[value2.prop]) {
      return value2.data[value2.prop];
    } else {
      return undefined;
    }
  }
  return value1.data[value1.prop];
}

export function getParentTask(currentTask, tasks) {
  if (!currentTask.parent_task_id) return;
  return tasks.find(
    (task) => task.integration_task_id === currentTask.parent_task_id
  );
}

export function getParentTaskName(currentTask, tasks) {
  const parentTask = getParentTask(currentTask, tasks);
  if (!parentTask) {
    return '';
  }
  return parentTask.name;
}
