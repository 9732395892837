import apolloClient from '@/apollo/client';
import {ACTIONS} from '@/utils/enums';
import {getCurrentWorkspaceMember} from '@/graphql/workspace.gql';
import {getWorkspaceAbility} from '@/permissions/workspace';
import {NotFoundError} from '@/utils/errors/NotFoundError';
import store from '@/store';

const canVisitPage = async (workspaceId, to) => {
  const workspace = await apolloClient.query({
    query: getCurrentWorkspaceMember,
    variables: {
      id: workspaceId,
    },
  }).then(({data})=>data.workspace);
  if (!workspace) {
    throw new NotFoundError;
  }
  const ability = getWorkspaceAbility(workspace);
  const page = to.meta.getPageResource(to);
  return ability.can(ACTIONS.VIEW, page);
};

export const workspaceParameterValidator = async (to) => {
  const {workspaceId} = to.params;
  return canVisitPage(workspaceId, to);
};

export const activeWorkspaceValidator = async (to) => {
  const {activeWorkspaceId} = store.state.workspace;
  return canVisitPage(activeWorkspaceId, to);
};
