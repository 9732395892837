import Vue from 'vue';
import VueMask from 'v-mask';
import * as format from 'string-format';
import ApexChart from 'vue-apexcharts';

import * as firebase from 'firebase/app';
import firebaseConfig from './firebase';

import './registerServiceWorker';
import router from './router';
import store from './store';
import {willPersistCache} from './apollo/client';
import App from './App.vue';

import apolloProvider from './plugins/apollo';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import './plugins/progress-bar';
import './plugins/integration';
import './plugins/api';

import VueCompositionAPI from '@vue/composition-api';

import './filters';

import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';

const ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT || 'development';
const SENTRY_DSN =
  process.env.VUE_APP_SENTRY_DSN ||
  'https://86bc458a6133412f9cb8d2268eaf4cfc@o553136.ingest.sentry.io/6191286';
format.extend(String.prototype, {});

Vue.use(VueMask);
Vue.use(VueCompositionAPI);
Vue.component('ApexChart', ApexChart);
Vue.config.productionTip = false;
Sentry.init({
  Vue,
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        'localhost',
        'plustracker.app',
        'dev.plustracker.app',
        /^\//,
      ],
    }),
  ],
  logErrors: ENVIRONMENT !== 'production',
  attachProps: true,
  attachStacktrace: true,
  debug: ENVIRONMENT !== 'production',
  tracesSampleRate: ENVIRONMENT === 'production' ? 0.2 : 1,
  tracingOptions: {
    trackComponents: true,
  },
});

firebase.initializeApp(firebaseConfig);
firebase.analytics();
willPersistCache()
  .then(() => {
    let app;
    firebase.auth().onAuthStateChanged(async (firebaseUser) => {
      firebaseUser &&
        Sentry.setUser({
          email: firebaseUser.email,
          id: firebaseUser.uid,
          username: firebaseUser.displayName,
        });
      if (!app) {
        app = new Vue({
          router,
          store,
          i18n,
          apolloProvider,
          async created() {
            if (!firebaseUser && store.getters['auth/isAuthorized']) {
              await this.$router.push('/logout');
            }
          },
          vuetify,
          render: (h) => h(App),
        }).$mount('#app');
      }
    });
  })
  .catch((e) => {
    console.error(e);
  });
