import {dateHours} from '@/filters';

export const TOOLTIP_WIDTH = 150;

export const TOOLTIP_HEIGHT = 100;

export const TIMELINE_INTERVAL_MILLISECONDS =
  Number(process.env.VUE_APP_TIMELINE_INTERVAL_MINUTES) * 60 * 1000;

export const HOUR_MILLISECONDS = 3600 * 1000;

export const TIMELINE_ACTIVITY_RANGE = [
  {
    color: 'green',
    min: 60,
    max: 100,
  },
  {
    color: 'orange',
    min: 30,
    max: 60,
  },
  {
    color: 'red',
    min: 0,
    max: 30,
  },
];

/**
 * Find an HTMLElement for the eventId for the dhtmlx library
 * @param {number | string} eventId - Id of the event
 * @return {Element} - HTML element for the given event id
 **/
export function getEventElement(eventId) {
  return document.querySelector(
      `.dhx_cal_event_line[event_id='${eventId}']`);
}

/**
 * Function to render a time interval as an HTML
 * @param {Object} timeInterval object to render
 * @return {string} - HTML as string
 **/
export function renderTimeInterval(timeInterval) {
  const {
    innerColor,
    taskName,
    startTime,
    stopTime,
    timeFormat,
    isImmutable,
    title,
  } = timeInterval;
  const isSyncedIconHtml =
    renderIcon({
      isDark: true,
      size: 'small',
      icon: isImmutable ? 'mdi-lock' : 'mdi-lock-open-variant',
    });
  return `
  <div class="time-interval">
    <div
      class="time-interval__project"
    >
      <span
        class="time-interval__project_title"
      >
        ${title}
      </span>
    </div>
    <div
      class="time-interval__content"
      style="background-color: ${innerColor}"
    >
      <div class="d-flex flex-column fix-flex-truncate">
        <div
          class="time-interval__task_title"
        >
          ${taskName}
        </div>
        <div
          class="time-interval__duration_range"
        >
          <span class="pr-1 truncate">
          ${dateHours(startTime, timeFormat)}
            - ${dateHours(stopTime, timeFormat)}
          </span>
          ${isSyncedIconHtml}
        </div>
      </div>
    </div>
  </div>`;
}

/**
 * Function to render a time interval as an HTML
 * @param {Object} props - Props to render the icon
 * @return {string} - HTML as string
 **/
function renderIcon(props) {
  const {
    isDark, icon, size,
  } = props;
  const sizeMap = {'small': '16px', 'x-small': '12px'};
  const themeClass = isDark ? 'theme--dark' : 'theme--light';
  const iconClasses = [
    'v-icon', 'notranslate', 'mdi', icon, themeClass,
  ];
  return `
    <i 
      aria-hidden="true" 
      style="font-size: ${sizeMap[size]}"
      class="${iconClasses.join(' ')}"
    >
    </i>
  `;
}

/**
 * Extract all attributes required to render a time interval
 * @param {Object} event - Event to extract the attributes
 * @param {Object} timeFormat - Active time format
 * @return {Object} - Entire time interval ready to render
 **/
export function formatTimeIntervalToRender(event, timeFormat) {
  const isNew = !event.task;
  let intervalProps;
  if (isNew) {
    intervalProps = {
      title: '',
      taskName: event.text,
      projectName: '',
      startTime: event.start_date,
      stopTime: event.end_date,
      innerColor: 'transparent',
      outerColor: 'transparent',
      timeFormat,
    };
  } else {
    const {project} = event.task.project_integration;
    intervalProps = {
      title: event.title,
      taskName: event.text,
      projectName: project.name,
      startTime: event.start_date,
      stopTime: event.end_date,
      innerColor: event.isActive ?
        'transparent' : event.innerColor,
      outerColor: event.isActive ?
        event.hover_color : event.color,
      isImmutable: event.immutable,
      timeFormat,
    };
  }
  return intervalProps;
}

/**
 * Builds the link structure for the task detail link
 * @param {Object} integration - Integration record,
 * must have a url and detail_task_url attributes
 * @return {String} - Final detail task url
 **/
export function getIntegrationTaskDetailLink({url, detail_task_url}) {
  return `${url}/${detail_task_url}`;
}

/**
 * Function to render a time entry detail as an HTML
 * @param {Object} params - Parameters used to render the time entry detail
 * @return {string} - HTML as string
 **/
export function renderTimeEntryDetail(params) {
  const {id, imageUrl, subtitle, badgeContent, badgeColor, isDark} = params;

  const themeClass = isDark ? 'theme--dark' : 'theme--light';
  const badgeStyle = `
    bottom: calc(100% - 12px); 
    left: calc(100% - 12px); 
    right: auto; 
    top: auto;`;
  const imageStyle =
    `background-image: url(${imageUrl}); background-position: center center;`;

  return `
      <div
        class="elevation-3 v-card v-sheet ${themeClass}"
        style="max-width: ${TOOLTIP_WIDTH}px; cursor: pointer;"
         onclick="window.dispatchEvent(
            new CustomEvent('open-time-entry', {detail: {id: ${id}}}))"
      >
        <span class="v-badge v-badge--overlap">
          <div
            class="v-image v-responsive"
            style="height: ${TOOLTIP_HEIGHT}px; width: ${TOOLTIP_WIDTH}px;"
          >
            <div
              class="v-responsive__sizer"
              style="padding-bottom: 66.6667%;"
            ></div>
            <div
              class="v-image__image v-image__image--cover"
              style="${imageStyle}"
            ></div>
            <div
              class="v-responsive__content"
              style="width: 150px;"
            ></div>
          </div>
          <span class="v-badge__wrapper">
            <span
              aria-atomic="true"
              aria-label="$vuetify.badge"
              aria-live="polite"
              role="status"
              class="v-badge__badge ${badgeColor}"
              style="${badgeStyle}"
            >
              ${badgeContent}
            </span>
          </span>
        </span>
        <div
          class="v-card__subtitle pt-0 pb-1 pr-0 primary--text cursor-pointer" 
        >
          ${subtitle}
        </div>
      </div>
`;
}
