import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloClient} from 'apollo-client';
import {persistCache} from 'apollo-cache-persist';
import resolvers from '../resolvers';
import COMBINED_LINK from './links';


const cache = new InMemoryCache();

/**
 * Async function to wait until the cache is persisted, otherwise
 * it would be possible that apollo queries won't be stored
 **/
export async function willPersistCache() {
  await persistCache({
    cache,
    storage: window.localStorage,
  });
}

export default new ApolloClient({
  link: COMBINED_LINK,
  cache,
  connectToDevTools: true,
  resolvers,
});

