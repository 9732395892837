import MessageFormat from 'messageformat';
/**
 * Class to format messages with MessageFormat package
 **/
export class I18nFormatter {
  /**
   * constructor to setup caching and message format package
   * @param {object} options - Include the default locale
   **/
  constructor(options = {}) {
    this._locale = options.locale || 'en';
    this._formatter = new MessageFormat(this._locale);
    this._caches = Object.create(null);
  }
  /**
   * Class to format messages with MessageFormat package
   * @param {string} message - Message to translate
   * @param {object} values - parameters to translate
   * @return {array} translation - Translated array
   **/
  interpolate(message, values) {
    let fn = this._caches[message];
    if (!fn) {
      fn = this._formatter.compile(message, this._locale);
      this._caches[message] = fn;
    }
    return [fn(values)];
  }
}
